const ListFieldsIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2611 11H7.73889C7.33081 11 7 11.4253 7 11.95V12.05C7 12.5747 7.33081 13 7.73889 13H20.2611C20.6692 13 21 12.5747 21 12.05V11.95C21 11.4253 20.6692 11 20.2611 11Z"
      fill={color}
    />
    <path
      d="M20.2611 16H7.73889C7.33081 16 7 16.4253 7 16.95V17.05C7 17.5747 7.33081 18 7.73889 18H20.2611C20.6692 18 21 17.5747 21 17.05V16.95C21 16.4253 20.6692 16 20.2611 16Z"
      fill={color}
    />
    <path
      d="M20.2611 6H7.73889C7.33081 6 7 6.42533 7 6.95V7.05C7 7.57467 7.33081 8 7.73889 8H20.2611C20.6692 8 21 7.57467 21 7.05V6.95C21 6.42533 20.6692 6 20.2611 6Z"
      fill={color}
    />
    <path
      d="M5 7C5 7.55228 4.55228 8 4 8C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6C4.55228 6 5 6.44772 5 7Z"
      fill={color}
    />
    <path
      d="M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12Z"
      fill={color}
    />
    <path
      d="M5 17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16C4.55228 16 5 16.4477 5 17Z"
      fill={color}
    />
  </svg>
);

export default ListFieldsIcon;
