import styled from "styled-components";
import { colorMixin } from "cssmixins";

const StyledDiv = styled.div`
  ${colorMixin}
`;

const H200BoldDiv = (props) => <StyledDiv className="H200-Bold" {...props} />;

export default H200BoldDiv;
