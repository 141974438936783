import styled from "styled-components";
import MenuItem from "components/menu-item";
import { useState } from "react";
import { useGetPage } from "hooks";

const Menu = ({ items, defaultPage }) => {
  const page = useGetPage();
  const [selectedId, setSelectedId] = useState(
    !page || page === "teacher" || page === "adminschool" ? defaultPage : page
  );

  return (
    <Container>
      {items.map(({ id, title, subitems }) => (
        <MenuItem
          key={id}
          title={title}
          subitems={subitems}
          id={id}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          page={page}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  float: left;
  height: 100vh;
  box-sizing: border-box;
  border: ${({ theme }) => theme.menu.border.width}px solid
    ${({ theme }) => theme.menu.border.color};
  border-radius: ${({ theme }) => theme.menu.border.radius}px;
  width: 100%;
  max-width: 350px;
  background-color: ${({ theme }) => theme.menu.background.color};
  margin-right: ${({ theme }) => theme.menu.margin.right}px;
  overflow: hidden;
  overflow-y: auto;
`;

export default Menu;
