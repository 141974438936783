import styled from "styled-components";
import { colorMixin } from "cssmixins";

const DivStyled = styled.div`
  ${colorMixin}
`;

const H100RegularDiv = (props) => (
  <DivStyled className="H100-Regular" {...props} />
);

export default H100RegularDiv;
