import styled from "styled-components";
import { colorMixin } from "cssmixins";

const StyledDiv = styled.div`
  ${colorMixin}
`;

const H200RegularDiv = (props) => (
  <StyledDiv className="H200-Regular" {...props} />
);

export default H200RegularDiv;
