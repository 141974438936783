import styled from "styled-components";
import { colorMixin } from "cssmixins";

const StyledUl = styled(({ margin, ...props }) => <ul {...props} />)`
  ${colorMixin}
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
`;

const Ul = (props) => <StyledUl {...props} />;

export default Ul;
