import { Outlet } from "react-router-dom";
import 'assets/global.css';
import 'assets/lms-user-guide/styles/guide.css';

const Layout = () => {
  return (
      <div className={'guide-container'}>
        <Outlet/>
      </div>
  );
};

export default Layout;
