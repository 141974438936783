import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import P16Div from "components/page/p16-div";
import Span from "components/page/span";
import P16BoldSpan from "components/page/p16-bold-span";
import IFrame from "components/page/iframe";
import { sesionesAgendaIFrame, sesionesFinalizadaIFrame } from "iframes";
import { useScroll } from "hooks";
import Ul from "components/page/ul";
import P16ListItem from "components/page/p16-list-item";
import IconDescription from "components/page/icon-description";
import { pdf, word } from "icons-names";
import H300BoldDiv from "components/page/h300-bold-div";
import { useTheme } from "styled-components";
import Box from "components/page/box";
import InfoLegend from "components/page/info-legend";
import P14Div from "components/page/p14-div";
import P14BoldSpan from "components/page/p14-bold-span";
import Row from "components/page/row";
import ToggleButton from "components/page/toggle-button";
import P12Div from "components/page/p12-div";
import Column from "components/page/column";
import Subtitle from "components/page/subtitle";
import macSVG from "assets/ic_desktop-outline.svg";
import Button from "components/page/button";
import styled from "styled-components";
import img1 from "assets/lms-user-guide/adminschool/Recursos.svg";
import img1Ca from "assets/lms-user-guide/adminschool/Recursos.cat.svg";
import Image from "components/page/image";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.adminschool.pages-content.resources";

const Resources = () => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
        position="sticky"
        top={0}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title`)}
      </H600BlackTitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.1`)}</Span>
        <ImageButtonContainer>
          <Image
            src={i18n.language === "ca" ? img1Ca : img1}
            isWithoutContainer
            maxWidth={theme.image.button.maxWidth}
          />
        </ImageButtonContainer>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.5`)}</Span>
      </P16Div>
      <IconDescription
        iconName={macSVG}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.3`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.4`)}</Span>
          </P16ListItem>
        }
        margin="30px 0 0"
        isSVG
      />
      <IconDescription
        iconName={pdf}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.3`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.4`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.5`)}
            </P16BoldSpan>
          </P16ListItem>
        }
        margin="50px 0 30px"
      />
      <IconDescription
        iconName={word}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3.3`)}
            </P16BoldSpan>
          </P16ListItem>
        }
        margin="50px 0 30px"
      />
    </PageContainer>
  );
};

const ImageButtonContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  --max-width: ${({ theme }) => theme.image.button.container.maxWidth};
`;

export default Resources;
