import styled from "styled-components";

const StyledDiv = styled(({ margin, type, ...props }) => <div {...props} />)`
  ${({
    theme: {
      box: {
        border: { width, radius },
      },
    },
  }) => `border:${width}px solid; border-radius:${radius}px;`}
  ${({
    theme: {
      box: {
        shadow: {
          offsetX,
          offsetY,
          blurRadius,
          spreadRadius,
          color: { red, green, blue, alpha },
        },
      },
    },
  }) =>
    `box-shadow:${offsetX}px ${offsetY}px ${blurRadius}px ${spreadRadius}px rgba(${red},${green},${blue},${alpha});`}
  ${({
    theme: {
      box: {
        padding: { vertical, horizontal },
      },
    },
  }) => `padding: ${vertical}px ${horizontal}px;`}
  ${({ type, theme }) => {
    switch (type) {
      case "warning":
        return `background-color:${theme.colors.warning10};border-color:${theme.colors.warning50};`;
      case "info":
        return `background-color:${theme.colors.info10};border-color:${theme.colors.info50};`;
      case "objective":
        return `background-color:${theme.colors.pink10};border-color:${theme.colors.pink50};`;
      case "more-info":
        return `background-color:${theme.colors.green10};border-color:${theme.colors.green50};`;
      default:
        return `background-color:${theme.colors.warning10};border-color:${theme.colors.warning50};`;
    }
  }}
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
`;

const Box = (props) => <StyledDiv {...props} />;

export default Box;
