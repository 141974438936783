import styled from "styled-components";

const Image = ({ center, isWithoutContainer = false, ...props }) => {
  return isWithoutContainer ? (
    <StyledImage {...props} />
  ) : (
    <ImageContainer center={center}>
      <StyledImage {...props} />
    </ImageContainer>
  );
};

const StyledImage = styled(({ height, width, margin, maxWidth, ...props }) => (
  <img alt="" {...props} />
))`
  ${({ height, width, margin, maxWidth }) =>
    `height: ${height};width: ${width}; margin:${margin};max-width:${maxWidth};`}
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  ${({ center }) => (!!center ? "justify-content:center;" : "")}
`;

export default Image;
