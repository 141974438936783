import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t("trackingToolGuide.info.page", { page: "tracking-tool-guide index" })}
    </div>
  );
};

export default Index;
