import styled, { useTheme } from "styled-components";
import Icon from "components/page/icon";
import Ul from "components/page/ul";

const BoxIconDescription = ({
  iconName,
  listItem = null,
  margin,
  iconSize,
  boxWidth,
  boxHeight,
  boxColor,
  isDescription = true,
  isInline = false,
}) => {
  const theme = useTheme();
  return (
    <SupraContainer margin={margin} isInline={isInline}>
      <Container>
        <IconContainer
          width={boxWidth}
          height={boxHeight}
          color={boxColor ?? "primary10"}
        >
          <Icon
            name={iconName}
            width={theme.dimensions.icon[iconSize ?? "medium"]}
            height={theme.dimensions.icon[iconSize ?? "medium"]}
          />
        </IconContainer>
        {isDescription && <Ul>{listItem}</Ul>}
      </Container>
    </SupraContainer>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ color, theme }) => `background-color:${theme.colors[color]};`}
  ${({ width }) => (width !== undefined ? `min-width:${width}px;` : "")}
    ${({ height }) => (height !== undefined ? `min-height:${height}px;` : "")}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SupraContainer = styled(({ isInline, ...props }) => <div {...props} />)`
  ${({ isInline }) => (isInline ? "display:inline-block;" : "")}
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
`;

export default BoxIconDescription;
