import { Outlet } from "react-router";
import Menu from "components/menu";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Layout = () => {
  const { t } = useTranslation();
  const TRANSLATION_PREFIX = "LMSUserGuide.teacher.menu.items";

  const menuItems = [
    {
      id: "conceptos-basicos",
      title: t(`${TRANSLATION_PREFIX}.conceptos-basicos.index`),
      subitems: [],
    },
    {
      id: "planifica",
      title: t(`${TRANSLATION_PREFIX}.planifica.index`),
      subitems: [
        {
          id: "paso-1",
          title: t(`${TRANSLATION_PREFIX}.planifica.paso-1.title`),
          subtitle: t(`${TRANSLATION_PREFIX}.planifica.paso-1.subtitle`),
        },
        {
          id: "paso-2",
          title: t(`${TRANSLATION_PREFIX}.planifica.paso-2.title`),
          subtitle: t(`${TRANSLATION_PREFIX}.planifica.paso-2.subtitle`),
        },
        {
          id: "paso-3",
          title: t(`${TRANSLATION_PREFIX}.planifica.paso-3.title`),
          subtitle: t(`${TRANSLATION_PREFIX}.planifica.paso-3.subtitle`),
        },
        {
          id: "paso-4",
          title: t(`${TRANSLATION_PREFIX}.planifica.paso-4.title`),
          subtitle: t(`${TRANSLATION_PREFIX}.planifica.paso-4.subtitle`),
        },
        {
          id: "paso-5",
          title: t(`${TRANSLATION_PREFIX}.planifica.paso-5.title`),
          subtitle: t(`${TRANSLATION_PREFIX}.planifica.paso-5.subtitle`),
        },
      ],
    },
    {
      id: "sesiones",
      title: t(`${TRANSLATION_PREFIX}.sesiones.index`),
      subitems: [
        {
          id: "recursos",
          title: t(`${TRANSLATION_PREFIX}.sesiones.recursos.title`),
          subtitle: "",
        },
        {
          id: "finalizada",
          title: t(`${TRANSLATION_PREFIX}.sesiones.finalizada.title`),
          subtitle: "",
        },
        {
          id: "muestra-practica",
          title: t(`${TRANSLATION_PREFIX}.sesiones.muestra-practica.title`),
          subtitle: "",
        },
        {
          id: "activa-practica",
          title: t(`${TRANSLATION_PREFIX}.sesiones.activa-practica.title`),
          subtitle: "",
        },
      ],
    },
    {
      id: "replanifica",
      title: t(`${TRANSLATION_PREFIX}.replanifica.index`),
      subitems: [
        {
          id: "atrasadas",
          title: t(`${TRANSLATION_PREFIX}.replanifica.atrasadas.title`),
          subtitle: "",
        },
      ],
    },
    {
      id: "practicas",
      title: t(`${TRANSLATION_PREFIX}.practicas.index`),
      subitems: [
        {
          id: "como-realizan",
          title: t(`${TRANSLATION_PREFIX}.practicas.como-realizan.title`),
          subtitle: "",
        },
        {
          id: "cuando-realizan",
          title: t(`${TRANSLATION_PREFIX}.practicas.cuando-realizan.title`),
          subtitle: "",
        },
        {
          id: "como-corrijen",
          title: t(`${TRANSLATION_PREFIX}.practicas.como-corrijen.title`),
          subtitle: "",
        },
        {
          id: "como-revisar",
          title: t(`${TRANSLATION_PREFIX}.practicas.como-revisar.title`),
          subtitle: "",
        },
      ],
    },
    {
      id: "informes",
      title: t(`${TRANSLATION_PREFIX}.informes.index`),
      subitems: [
        {
          id: "uso",
          title: t(`${TRANSLATION_PREFIX}.informes.uso.title`),
          subtitle: "",
        },
        {
          id: "estudiantes",
          title: t(`${TRANSLATION_PREFIX}.informes.estudiantes.title`),
          subtitle: "",
        },
      ],
    },
    {
      id: "estudiantes",
      title: t(`${TRANSLATION_PREFIX}.estudiantes.index`),
      subitems: [
        {
          id: "codigo",
          title: t(`${TRANSLATION_PREFIX}.estudiantes.codigo.title`),
          subtitle: "",
        },
      ],
    },
    {
      id: "recursos",
      title: t(`${TRANSLATION_PREFIX}.recursos.index`),
      subitems: [],
    },
  ];

  return (
    <Container>
      <Menu items={menuItems} defaultPage={"conceptos-basicos"} />
      <Outlet />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.background.color};
`;

export default Layout;
