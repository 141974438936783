import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import { useScroll } from "hooks";
import P16Div from "components/page/p16-div";
import { useTheme } from "styled-components";
import Subtitle from "components/page/subtitle";

const PAGE_TRANSLATIONS_PREFIX =
  "trackingToolGuide.teacher.pages-content.first-questions";

const FirstQuestions = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  const theme = useTheme();

  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title`)}
      </H600BlackTitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1`)}
      </P16Div>
      <Subtitle>{t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.1`)}</Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2`)}
      </P16Div>
      <Subtitle>{t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.4`)}</Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6`)}
      </P16Div>
      {language === "ca" && (
        <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
          {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7`)}
        </P16Div>
      )}
      <Subtitle>{t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.2`)}</Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3`)}
      </P16Div>
      <Subtitle>{t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.3`)}</Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4`)}
      </P16Div>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5`)}
      </P16Div>
    </PageContainer>
  );
};

export default FirstQuestions;
