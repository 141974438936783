import styled from "styled-components";
import { colorMixin } from "cssmixins";

const StyledListItem = styled(({ margin, ...props }) => <li {...props} />)`
  ${colorMixin}
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
`;

const P16ListItem = (props) => <StyledListItem className="P16" {...props} />;

export default P16ListItem;
