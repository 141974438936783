import styled, { useTheme } from "styled-components";
import Icon from "components/page/icon";
import Ul from "components/page/ul";
import IconSVG from "components/page/icon-svg";

const IconDescription = ({ iconName, listItem, margin, isSVG = false }) => {
  const theme = useTheme();
  return (
    <Container margin={margin}>
      {isSVG ? (
        <IconSVG
          width={theme.dimensions.icon.big}
          height={theme.dimensions.icon.big}
          src={iconName}
        />
      ) : (
        <Icon
          name={iconName}
          width={theme.dimensions.icon.big}
          height={theme.dimensions.icon.big}
        />
      )}
      <Ul>{listItem}</Ul>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
`;

export default IconDescription;
