import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import P16Div from "components/page/p16-div";
import P16BoldSpan from "components/page/p16-bold-span";
import Span from "components/page/span";
import Ul from "components/page/ul";
import P16ListItem from "components/page/p16-list-item";
import Box from "components/page/box";
import P14Div from "components/page/p14-div";
import InfoLegend from "components/page/info-legend";
import IconDescription from "components/page/icon-description";
import {
  pdf,
  spellCheck,
  notepadPerson,
  textClock,
  calendar,
  calendarClock,
  flashCheck,
  arrowCheck,
  editPeople,
  key,
  box,
  starCheck,
  personCheck,
  sidebarReverse,
} from "icons-names";
import P14BoldSpan from "components/page/p14-bold-span";
import { useScroll } from "hooks";
import macSVG from "assets/ic_desktop-outline.svg";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.teacher.pages-content.teacher.conceptos-basicos";

const Index = () => {
  const { t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title`)}
      </H600BlackTitle>
      <P16Div margin="0 0 20px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p0.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p0.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p0.3`)}</Span>
      </P16Div>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.3`)}</Span>
      </P16Div>
      <Ul>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l1.1`)}</P16BoldSpan>
        </P16ListItem>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l1.2`)}</P16BoldSpan>
        </P16ListItem>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l1.3`)}</P16BoldSpan>
        </P16ListItem>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l1.4`)}</P16BoldSpan>
        </P16ListItem>
      </Ul>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p2.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p2.2`)}</P16BoldSpan>
      </P16Div>
      <IconDescription
        iconName={macSVG}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l2.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l2.1.2`)}</Span>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l2.1.3`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l2.1.4`)}</Span>
          </P16ListItem>
        }
        margin="30px 0 0"
        isSVG
      />
      <IconDescription
        iconName={pdf}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l3.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l3.1.2`)}</Span>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l3.1.3`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l3.1.4`)}</Span>
          </P16ListItem>
        }
        margin="50px 0 30px"
      />
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p3.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p3.2`)}</P16BoldSpan>
      </P16Div>
      <IconDescription
        iconName={spellCheck}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l4.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l4.1.2`)}</Span>
          </P16ListItem>
        }
        margin="30px 0 0"
      />
      <IconDescription
        iconName={notepadPerson}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l5.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l5.1.2`)}</Span>
          </P16ListItem>
        }
        margin="50px 0"
      />
      <IconDescription
        iconName={textClock}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l6.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l6.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <Box type="info" margin="50px 30px">
        <InfoLegend />
        <P14Div color="info80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.3`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.4`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.5`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.6`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.7`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.8`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.9`)}</Span>
        </P14Div>
      </Box>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p4.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p4.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p4.3`)}</Span>
      </P16Div>
      <IconDescription
        iconName={calendar}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l7.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l7.1.2`)}</Span>
          </P16ListItem>
        }
        margin="30px 0 50px"
      />
      <IconDescription
        iconName={calendarClock}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l8.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l8.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={flashCheck}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l9.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l9.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={arrowCheck}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l10.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l10.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={editPeople}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l11.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l11.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={key}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l12.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l12.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={sidebarReverse}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l18.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l18.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={box}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l13.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l13.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 30px"
      />
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p5.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p5.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p5.3`)}</Span>
      </P16Div>
      <IconDescription
        iconName={calendar}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l14.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l14.1.2`)}</Span>
          </P16ListItem>
        }
        margin="30px 0 50px"
      />{" "}
      <IconDescription
        iconName={arrowCheck}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l15.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l15.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={starCheck}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l16.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l16.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={personCheck}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l17.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l17.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 30px"
      />
    </PageContainer>
  );
};

export default Index;
