const ObjectiveIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.002 22.0029C17.526 22.0029 22.004 17.5249 22.004 12.0019C22.004 6.47794 17.526 1.99994 12.002 1.99994C6.478 1.99994 2 6.47794 2 12.0019C2 17.5249 6.478 22.0029 12.002 22.0029ZM12.002 20.5029C9.74739 20.5029 7.58513 19.6073 5.99089 18.0131C4.39664 16.4188 3.501 14.2565 3.501 12.0019C3.501 9.74733 4.39664 7.58507 5.99089 5.99082C7.58513 4.39658 9.74739 3.50094 12.002 3.50094C14.2567 3.50094 16.4191 4.39663 18.0135 5.99097C19.6078 7.58531 20.5035 9.7477 20.5035 12.0024C20.5035 14.2572 19.6078 16.4196 18.0135 18.0139C16.4191 19.6082 14.2567 20.5039 12.002 20.5039V20.5029Z"
      fill={color}
    />
    <path
      d="M12 17.9993C15.3137 17.9993 18 15.3131 18 11.9999C18 8.6862 15.3137 5.99994 12 5.99994C8.68626 5.99994 6 8.6862 6 11.9999C6 15.3131 8.68626 17.9993 12 17.9993ZM12 17.0995C10.6475 17.0995 9.35041 16.5622 8.39405 15.6059C7.4377 14.6495 6.90042 13.3524 6.90042 11.9999C6.90042 10.6474 7.4377 9.35035 8.39405 8.39399C9.35041 7.43763 10.6475 6.90036 12 6.90036C13.3526 6.90036 14.6497 7.43767 15.6062 8.39408C16.5626 9.35049 17.0999 10.6477 17.0999 12.0002C17.0999 13.3528 16.5626 14.65 15.6062 15.6064C14.6497 16.5628 13.3526 17.0995 12 17.0995Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M12 13.9998C13.1046 13.9998 14 13.1044 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1044 10.8954 13.9998 12 13.9998ZM12 13.6999C11.5492 13.6999 11.1168 13.5208 10.798 13.202C10.4792 12.8832 10.3001 12.4508 10.3001 12C10.3001 11.5492 10.4792 11.1168 10.798 10.798C11.1168 10.4792 11.5492 10.3001 12 10.3001C12.4509 10.3001 12.8832 10.4792 13.2021 10.798C13.5209 11.1169 13.7 11.5492 13.7 12.0001C13.7 12.451 13.5209 12.8833 13.2021 13.2022C12.8832 13.521 12.4509 13.6999 12 13.6999Z"
      fill={color}
      stroke={color}
      stroke-width="2.5"
    />
    <path
      d="M20.377 2.52239L11.5225 11.3769C11.2339 11.6655 11.3008 12.2002 11.6718 12.5712L11.7425 12.6419C12.1135 13.0129 12.6481 13.0797 12.9367 12.7912L21.7912 3.93661C22.0798 3.64805 22.013 3.11338 21.642 2.74238L21.5712 2.67167C21.2002 2.30067 20.6656 2.23384 20.377 2.52239Z"
      fill={color}
    />
  </svg>
);

export default ObjectiveIcon;
