import { forwardRef } from "react";
import H400BlackDiv from "./h400-black-div";
import styled from "styled-components";

const Subtitle = styled(
  forwardRef(({ margin, ...props }, ref) => (
    <H400BlackDiv {...props} ref={ref} />
  ))
)`
  margin-bottom: ${({ theme }) => theme.dimensions.title.marginBottom}px;
  ${({ margin }) => (margin ? `margin:${margin};` : "")}
`;

export default Subtitle;
