import styled from "styled-components";
import { colorMixin } from "cssmixins";

const StyledDiv = styled.div`
  ${colorMixin}
`;

const H300BoldDiv = (props) => <StyledDiv className="H300-Bold" {...props} />;

export default H300BoldDiv;
