import Box from "components/page/box";
import H600BlackTitle from "components/page/h600-black-title";
import InfoLegend from "components/page/info-legend";
import P14Div from "components/page/p14-div";
import P16BoldSpan from "components/page/p16-bold-span";
import P16Div from "components/page/p16-div";
import P16ListItem from "components/page/p16-list-item";
import PageContainer from "components/page/page-container";
import Span from "components/page/span";
import Subtitle from "components/page/subtitle";
import Ul from "components/page/ul";
import WarningLegend from "components/page/warning-legend";
import { useScroll } from "hooks";
import { useTranslation } from "react-i18next";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.teacher.pages-content.teacher.informes";

const Informes = () => {
  const { sectionsRefs, containerRef } = useScroll();
  const { t } = useTranslation();
  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.1`)}
      </H600BlackTitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.5`)}</Span>
      </P16Div>
      <Ul>
        <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1`)}</P16ListItem>
        <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2`)}</P16ListItem>
        <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3`)}</P16ListItem>
      </Ul>
      <P16Div margin="30px 0">{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2`)}</P16Div>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3`)}</P16Div>
      <Box type="warning" margin="30px 30px">
        <WarningLegend />
        <P14Div color="warning80">
          {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1`)}
        </P14Div>
      </Box>
      <P16Div margin="0 0 50px">{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4`)}</P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.2`)}
      </Subtitle>
      <P16Div margin="0 0 20px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.4`)}</P16BoldSpan>
      </P16Div>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6`)}</P16Div>
      <Ul>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.2`)}</Span>
        </P16ListItem>
      </Ul>
      <Box type="info" margin="30px 30px">
        <InfoLegend />
        <P14Div color="info80">
          {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.2.1`)}
        </P14Div>
      </Box>
      <Ul>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.2`)}</Span>
        </P16ListItem>
      </Ul>
      <Box type="info" margin="30px 30px 80px 30px">
        <InfoLegend />
        <P14Div color="info80">
          {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.3.1`)}
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[2] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.3`)}
      </Subtitle>
      <P16Div margin="0 0 20px">{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7`)}</P16Div>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.8`)}</P16Div>
      <Ul>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1.2`)}</Span>
        </P16ListItem>
      </Ul>
      <Box type="info" margin="30px 30px">
        <InfoLegend />
        <P14Div color="info80">
          {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.4.1`)}
        </P14Div>
      </Box>
      <Ul margin="0 0 30px">
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.1.2`)}</Span>
        </P16ListItem>
      </Ul>
      <P16Div height={370} />
    </PageContainer>
  );
};

export default Informes;
