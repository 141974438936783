import styled from "styled-components";

const ASPECT_RATIO = 1.75;
const OVAL_FACTOR = 2.8;

const ToggleButton = ({ width, color, radius }) => {
  return (
    <Base width={width} color={color} radius={radius}>
      <Oval width={width} />
    </Base>
  );
};

const Base = styled.div`
  position: relative;
  ${({ width }) => `min-width:${width}px;min-height:${width / ASPECT_RATIO}px;`}
  ${({ color, theme }) =>
    `background-color:${theme.colors[color ?? "success50"]};`}
  ${({ radius }) => `border-radius:${radius ?? "12"}px;`}
`;

const Oval = styled.div`
  position: absolute;
  left: 53.57%;
  right: 10.71%;
  top: 18.75%;
  bottom: 18.75%;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ width }) =>
    `min-width:${width / OVAL_FACTOR}px;min-height:${width / OVAL_FACTOR}px;`}
  border-radius:50%;
`;

export default ToggleButton;
