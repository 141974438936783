import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import P16Div from "components/page/p16-div";
import Span from "components/page/span";
import P16BoldSpan from "components/page/p16-bold-span";
import IFrame from "components/page/iframe";
import { sesionesAgendaIFrame, sesionesFinalizadaIFrame } from "iframes";
import { useScroll, useScrollEnd } from "hooks";
import Ul from "components/page/ul";
import P16ListItem from "components/page/p16-list-item";
import IconDescription from "components/page/icon-description";
import { pdf, word } from "icons-names";
import H300BoldDiv from "components/page/h300-bold-div";
import { useTheme } from "styled-components";
import Box from "components/page/box";
import InfoLegend from "components/page/info-legend";
import P14Div from "components/page/p14-div";
import P14BoldSpan from "components/page/p14-bold-span";
import Row from "components/page/row";
import ToggleButton from "components/page/toggle-button";
import P12Div from "components/page/p12-div";
import Column from "components/page/column";
import Subtitle from "components/page/subtitle";
import macSVG from "assets/ic_desktop-outline.svg";
import WarningLegend from "components/page/warning-legend";
import Ol from "components/page/ol";
import P14ListItem from "components/page/p14-list-item";
import img1 from "assets/lms-user-guide/adminschool/id_class.png";
import img1Ca from "assets/lms-user-guide/adminschool/id_class_ca.png";
import img2 from "assets/lms-user-guide/adminschool/Profesor-correo.png";
import img2Ca from "assets/lms-user-guide/adminschool/Profesor-correo_ca.png";
import img3 from "assets/lms-user-guide/adminschool/profesor-plantilla.png";
import img4 from "assets/lms-user-guide/adminschool/estudiante-plantilla.png";
import img5 from "assets/lms-user-guide/adminschool/Usuarios.svg";
import img5Ca from "assets/lms-user-guide/adminschool/Usuaris.cat.svg";
import img6 from "assets/lms-user-guide/adminschool/+Usuario.svg";
import img6Ca from "assets/lms-user-guide/adminschool/+Usuari.cat.svg";
import img7 from "assets/lms-user-guide/adminschool/Clases.svg";
import img7Ca from "assets/lms-user-guide/adminschool/Classes.cat.svg";
import Image from "components/page/image";
import video7 from "assets/lms-user-guide/adminschool/007.mov";
import video7Ca from "assets/lms-user-guide/adminschool/007_ca.mov";
import video8 from "assets/lms-user-guide/adminschool/008.mov";
import video8Ca from "assets/lms-user-guide/adminschool/008_ca.mov";
import video5 from "assets/lms-user-guide/adminschool/005.mov";
import video5Ca from "assets/lms-user-guide/adminschool/005_ca.mov";
import Video from "components/page/video";
import Button from "components/page/button";
import styled from "styled-components";

const PAGE_TRANSLATIONS_PREFIX = "LMSUserGuide.adminschool.pages-content.users";

const Users = () => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  useScrollEnd(containerRef);

  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
        position="sticky"
        top={0}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title`)}
      </H600BlackTitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.2`)}</P16BoldSpan>
      </P16Div>
      <P16Div margin={`0 0 30px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.1`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.2`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.3`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.4`)}</Span>
      </P16Div>
      <Box
        type="warning"
        margin={`0 ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px`}
      >
        <WarningLegend />
        <P14Div color="warning80">
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3`)}</P14BoldSpan>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.1`)}
      </Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4`)}
      </P16Div>
      <Ol margin={`0 0 30px`}>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.1`)}</Span>
          <ImageButtonContainer>
            <Image
              src={i18n.language === "ca" ? img5Ca : img5}
              isWithoutContainer
              maxWidth={theme.image.button.maxWidth}
            />
          </ImageButtonContainer>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.3`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.1`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.2`)}</P16BoldSpan>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3.1`)}</Span>
          <ImageButtonContainer>
            <Image
              src={i18n.language === "ca" ? img6Ca : img6}
              isWithoutContainer
              maxWidth={theme.image.button.maxWidth}
            />
          </ImageButtonContainer>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3.3`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.2`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.3`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.4`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.5`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.6`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.7`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.8`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.1`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.2`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.3`)}</Span>
        </P16ListItem>
      </Ol>
      <Box
        type="warning"
        margin={`0 ${theme.dimensions.box.margin}px  50px  ${theme.dimensions.box.margin}px`}
      >
        <WarningLegend />
        <P14Div color="warning80">
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6`)}</P14BoldSpan>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[2] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.2`)}
      </Subtitle>
      <P16Div margin="0 0 20px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7.3`)}</Span>
      </P16Div>
      <P16Div margin="0 0 30px">{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.8`)}</P16Div>
      <P16Div margin="0 0 20px">
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.9`)}</P16BoldSpan>
      </P16Div>
      <P16Div margin="0 0 20px">
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10`)}
      </P16Div>
      <Ol margin="0 0 30px">
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.2`)}</Span>
          <ImageButtonContainer>
            <Image
              src={i18n.language === "ca" ? img5Ca : img5}
              isWithoutContainer
              maxWidth={theme.image.button.maxWidth}
            />
          </ImageButtonContainer>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.4`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.2.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.2.2`)}</Span>
        </P16ListItem>
      </Ol>
      <Image src={img3} maxWidth="528px" center />
      <Box type="info" margin={`${theme.dimensions.box.margin}px`}>
        <InfoLegend />
        <P14Div color="info80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.11.1`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.11.2`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.11.3`)}</Span>
        </P14Div>
        <Ol>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.2`)}</Span>
            <ImageButtonContainer>
              <Image
                src={i18n.language === "ca" ? img7Ca : img7}
                isWithoutContainer
                maxWidth={theme.image.button.maxWidth}
              />
            </ImageButtonContainer>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.4`)}</Span>
          </P14ListItem>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.2.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.2.2`)}</Span>
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.2.3`)}
            </P14BoldSpan>
          </P14ListItem>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.3.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.3.2`)}</Span>
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.3.3`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.3.4`)}</Span>
          </P14ListItem>
        </Ol>
        <Image
          src={i18n.language === "ca" ? img1Ca : img1}
          maxWidth={theme.image.box.maxWidth}
          center
        />
      </Box>
      <Box
        type="warning"
        margin={`0 ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px`}
      >
        <WarningLegend />
        <Ol>
          <P14ListItem color="warning80">
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1.1`)}</Span>
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1.2`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1.3`)}</Span>
          </P14ListItem>
          <P14ListItem color="warning80">
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.2.1`)}</Span>
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.2.2`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.2.3`)}</Span>
          </P14ListItem>
        </Ol>
      </Box>
      <Ol start="3">
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.12.1`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.12.2`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.12.3`)}</Span>
        </P16ListItem>
      </Ol>
      <Box
        type="info"
        margin={`0 ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px`}
      >
        <InfoLegend />
        <P14Div
          color="info80"
          margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        >
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13.1`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13.2`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13.3`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13.4`)}</P14BoldSpan>
        </P14Div>
        <Image
          src={i18n.language === "ca" ? img2Ca : img2}
          maxWidth={theme.image.box.maxWidth}
          center
          margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        />
        <P14Div color="info80">
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.14.1`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.14.2`)}</Span>
        </P14Div>
      </Box>
      <Video
        src={i18n.language === "ca" ? video7Ca : video7}
        controls
        maxWidth={theme.video.maxWidth}
        margin="0 0 50px"
        center
      />
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.15`)}</P16BoldSpan>
      </P16Div>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.16`)}
      </P16Div>
      <Ol margin="0 0 30px">
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.1.2`)}</Span>
          <ImageButtonContainer>
            <Image
              src={i18n.language === "ca" ? img5Ca : img5}
              isWithoutContainer
              maxWidth={theme.image.button.maxWidth}
            />
          </ImageButtonContainer>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.1.4`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.2.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.2.2`)}</Span>
        </P16ListItem>
      </Ol>
      <Image src={img4} center maxWidth="598px" />
      <Box
        type="info"
        margin={`${theme.dimensions.box.margin}px ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px`}
      >
        <InfoLegend />
        <P14Div
          color="info80"
          margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        >
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.17.1`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.17.2`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.17.3`)}</Span>
        </P14Div>
        <Ol>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.1.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.1.2`)}</Span>
            <ImageButtonContainer>
              <Image
                src={i18n.language === "ca" ? img7Ca : img7}
                isWithoutContainer
                maxWidth={theme.image.button.maxWidth}
              />
            </ImageButtonContainer>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.1.4`)}</Span>
          </P14ListItem>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.2.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.2.2`)}</Span>
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.2.3`)}
            </P14BoldSpan>
          </P14ListItem>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.3.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.3.2`)}</Span>
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.3.3`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.6.3.4`)}</Span>
          </P14ListItem>
        </Ol>
        <Image
          src={i18n.language === "ca" ? img1Ca : img1}
          maxWidth={theme.image.box.maxWidth}
          center
          margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        />
      </Box>
      <Box
        type="warning"
        margin={`0 ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px`}
      >
        <WarningLegend />
        <Ol>
          <P14ListItem color="warning80">
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.7.1.1`)}</Span>
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.7.1.2`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.7.1.3`)}</Span>
          </P14ListItem>
          <P14ListItem color="warning80">
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.7.2.1`)}</Span>
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.7.2.2`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.7.2.3`)}</Span>
          </P14ListItem>
        </Ol>
      </Box>
      <Ol start={3}>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.8.1.1`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.8.1.2`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.8.1.3`)}</Span>
        </P16ListItem>
      </Ol>
      <Video
        src={i18n.language === "ca" ? video8Ca : video8}
        controls
        maxWidth={theme.video.maxWidth}
        margin="30px 0 50px"
        center
      />
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[3] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.3`)}
      </Subtitle>
      <Box
        type="info"
        margin={`0 ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px`}
      >
        <InfoLegend />
        <P14Div color="info80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.1`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.2`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.3`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.4`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.5`)}</Span>
          <ImageButtonContainer>
            <Image
              src={i18n.language === "ca" ? img5Ca : img5}
              isWithoutContainer
              maxWidth={theme.image.button.maxWidth}
            />
          </ImageButtonContainer>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.7`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.8`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.9`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.10`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.11`)}</Span>
        </P14Div>
      </Box>
      <Video
        src={i18n.language === "ca" ? video5Ca : video5}
        controls
        maxWidth={theme.video.maxWidth}
        center
        margin="30px 0 50px"
      />
    </PageContainer>
  );
};

const ImageButtonContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  --max-width: ${({ theme }) => theme.image.button.container.maxWidth};
`;

export default Users;
