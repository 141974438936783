import H600BlackTitle from "components/page/h600-black-title";
import P16Div from "components/page/p16-div";
import P16ListItem from "components/page/p16-list-item";
import PageContainer from "components/page/page-container";
import Subtitle from "components/page/subtitle";
import Ul from "components/page/ul";
import { useScroll } from "hooks";
import { useTranslation } from "react-i18next";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.teacher.pages-content.teacher.estudiantes";

const Estudiantes = () => {
  const { sectionsRefs, containerRef } = useScroll();
  const { t } = useTranslation();
  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.1`)}
      </H600BlackTitle>
      <P16Div margin="0 0 30px">{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1`)}</P16Div>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2`)}</P16Div>
      <Ul margin="0 0 50px">
        <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1`)}</P16ListItem>
        <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2`)}</P16ListItem>
        <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3`)}</P16ListItem>
      </Ul>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.2`)}
      </Subtitle>
      <P16Div margin="0 0 30px">{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3`)}</P16Div>
      <P16Div height={640} />
    </PageContainer>
  );
};

export default Estudiantes;
