import Box from "components/page/box";
import Button from "components/page/button";
import H100RegularDiv from "components/page/h100-regular-div";
import H200BoldDiv from "components/page/h200-bold-div";
import H400BlackDiv from "components/page/h400-black-div";
import H600BlackTitle from "components/page/h600-black-title";
import IFrame from "components/page/iframe";
import InfoLegend from "components/page/info-legend";
import P14BoldSpan from "components/page/p14-bold-span";
import P14Div from "components/page/p14-div";
import P16BoldSpan from "components/page/p16-bold-span";
import P16Div from "components/page/p16-div";
import P16ListItem from "components/page/p16-list-item";
import PageContainer from "components/page/page-container";
import Row from "components/page/row";
import Span from "components/page/span";
import Subtitle from "components/page/subtitle";
import Ul from "components/page/ul";
import { useScroll } from "hooks";
import {
  practicasCorreccionIFrame,
  practicasCorregirIFrame,
  practicasDosIFrame,
  practicasRespuestaAbierta,
  practicasRespuestaCerrada,
  practicasRevisar,
  practicasUnoIFrame,
} from "iframes";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.teacher.pages-content.teacher.practicas";

const Practicas = () => {
  const { sectionsRefs, containerRef } = useScroll();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.1`)}
      </H600BlackTitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.5`)}</Span>
      </P16Div>
      <IFrame
        {...practicasUnoIFrame}
        margin={`30px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.5`)}</Span>
      </P16Div>
      <IFrame
        {...practicasDosIFrame}
        margin={`30px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3.5`)}</Span>
      </P16Div>
      <Row justifyContent="space-around" margin="20px 0 50px 0">
        <Button margin="0 20px 0 10px" type="label" color="lime40">
          <H100RegularDiv>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.2`)}
          </H100RegularDiv>
        </Button>
        <Button margin="0 20px 0 0" type="label" color="primary30">
          <H100RegularDiv>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.3`)}
          </H100RegularDiv>
        </Button>
        <Button margin="0 20px 0 0" type="label" color="pink50">
          <H100RegularDiv>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.4`)}
          </H100RegularDiv>
        </Button>
      </Row>
      {/* <Ul margin="20px 0 50px 0">
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.1`)}</Span>
          <Button margin="0 20px 0 10px" type="label" color="lime40">
            <H100RegularDiv>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.2`)}
            </H100RegularDiv>
          </Button>
          <Button margin="0 20px 0 0" type="label" color="primary30">
            <H100RegularDiv>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.3`)}
            </H100RegularDiv>
          </Button>
          <Button margin="0 20px 0 0" type="label" color="pink50">
            <H100RegularDiv>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.4`)}
            </H100RegularDiv>
          </Button>
        </P16ListItem>
      </Ul> */}
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.2`)}
      </Subtitle>
      <P16Div margin="0 0 50px 0">
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4`)}
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[2] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.3`)}
      </Subtitle>
      <P16Div margin="0 0 50px 0">
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5`)}
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[3] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.4`)}
      </Subtitle>
      <H400BlackDiv>{t(`${PAGE_TRANSLATIONS_PREFIX}.hs.1`)}</H400BlackDiv>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6`)}</P16Div>
      <IFrame
        {...practicasCorreccionIFrame}
        margin={`30px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <H400BlackDiv>{t(`${PAGE_TRANSLATIONS_PREFIX}.hs.2`)}</H400BlackDiv>
      <P16Div margin="0 0 20px 0">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7.2`)}</P16BoldSpan>
      </P16Div>
      <P16Div margin="0 0 20px 0">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.8.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.8.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.8.3`)}</Span>
        <Button margin="0 10px" type="button3" color="primary50">
          <H200BoldDiv color="white">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.2`)}
          </H200BoldDiv>
        </Button>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.8.4`)}</Span>
      </P16Div>
      {/* <Ul>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.1`)}</Span>
          <Button margin="0 10px" type="button3" color="primary50">
            <H200BoldDiv color="white">
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.2`)}
            </H200BoldDiv>
          </Button>
        </P16ListItem>
      </Ul> */}
      <IFrame
        {...practicasCorregirIFrame}
        margin={`30px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <P16Div margin="0 0 20px 0">
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.9`)}
      </P16Div>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10`)}</P16Div>
      <IFrame
        {...practicasRespuestaCerrada}
        margin={`30px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.11`)}</P16Div>
      <IFrame
        {...practicasRespuestaAbierta}
        margin={`30px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <P16Div margin="0 0 20px 0">
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.12`)}
      </P16Div>
      <P16Div margin="0 0 20px 0">
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13.1`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13.2`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13.3`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13.4`)}</Span>
      </P16Div>
      <P16Div margin="0 0 20px 0">
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.14.1`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.14.2`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.14.3`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.14.4`)}</Span>
      </P16Div>
      <P16Div margin="0 0 20px 0">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.15.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.15.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.15.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.15.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.15.5`)}</Span>
      </P16Div>
      <P16Div margin="0 0 20px 0">
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.16`)}
      </P16Div>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.17`)}</P16Div>
      <Ul>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.2`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.2.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.2.2`)}</Span>
        </P16ListItem>
      </Ul>
      <Box type="info" margin="30px 30px">
        <InfoLegend />
        <P14Div color="info80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.3`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.4`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.5`)}</Span>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[4] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.5`)}
      </Subtitle>
      <P16Div margin="0 0 20px 0">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.5`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.6`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.7`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.18.8`)}</P16BoldSpan>
      </P16Div>
      <P16Div margin="0 0 20px 0">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.19.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.19.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.19.3`)}</Span>
      </P16Div>
      <P16Div margin="0 0 20px 0">
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.20`)}
      </P16Div>
      <Row justifyContent="center">
        <Row gap={100}>
          <Button type="label" color="lime40">
            <H100RegularDiv>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.2`)}
            </H100RegularDiv>
          </Button>
          <Button type="label" color="primary30">
            <H100RegularDiv>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.3`)}
            </H100RegularDiv>
          </Button>
          <Button type="label" color="pink50">
            <H100RegularDiv>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.4`)}
            </H100RegularDiv>
          </Button>
        </Row>
      </Row>
      <IFrame
        {...practicasRevisar}
        margin={`30px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
    </PageContainer>
  );
};

export default Practicas;
