export const planificaIFrame = {
  src: "https://player.vimeo.com/video/825791987?h=ca46214b28&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Planificador - Paso 04 - Confirmar lecciones y prácticas",
};

export const sesionesAgendaIFrame = {
  src: "https://player.vimeo.com/video/825809039?h=6d6df06548&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Agenda - Preparar e impartir sesiones",
};

export const sesionesFinalizadaIFrame = {
  src: "https://player.vimeo.com/video/825809103?h=5120ff9a7d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Confirmar una sesión como finalizada",
};

export const sesionesActivaIFrame = {
  src: "https://player.vimeo.com/video/826144435?h=55367dc715&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Activa una práctica antes de su planificación",
};

export const practicasUnoIFrame = {
  src: "https://player.vimeo.com/video/826145731?h=04822d0b74&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Las prácticas de tus estudiantes",
};

export const practicasDosIFrame = {
  src: "https://player.vimeo.com/video/826145764?h=4b4d22ff02&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Las prácticas de tus estudiantes 2",
};

export const practicasCorreccionIFrame = {
  src: "https://player.vimeo.com/video/826166242?h=849b1cd6a9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Un estudiante revisa la corrección del ejercicio",
};

export const practicasCorregirIFrame = {
  src: "https://player.vimeo.com/video/826854996?h=6d4c417813&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Corregir una práctica",
};

export const practicasRespuestaCerrada = {
  src: "https://player.vimeo.com/video/827537476?h=2cb0cb04a5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Correcciones respuesta cerrada",
};

export const practicasRespuestaAbierta = {
  src: "https://player.vimeo.com/video/827537441?h=54cb13f0b7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Correcciones respuesta abierta",
};

export const practicasRevisar = {
  src: "https://player.vimeo.com/video/826166801?h=706248c0a2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  title: "Revisar las prácticas de tus estudiantes",
};
