import styled from "styled-components";

const Row = ({ children, margin, gap, justifyContent }) => {
  return (
    <Container margin={margin} gap={gap} justifyContent={justifyContent}>
      {children}
    </Container>
  );
};

const Container = styled(({ justifyContent, ...props }) => <div {...props} />)`
  display: flex;
  align-items: center;
  ${({ justifyContent }) =>
    !!justifyContent ? `justify-content:${justifyContent};` : ""}
  ${({ gap }) => (gap !== undefined ? `gap:${gap}px;` : "")}
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
`;

export default Row;
