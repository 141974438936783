import P14Div from "components/page/p14-div";
import Icon from "components/page/icon";
import { edit } from "icons-names";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import H200BoldDiv from "./h200-bold-div";

const InfoLegend = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container color="info80" margin="0 0 10px">
      <Icon
        name={edit}
        width={theme.dimensions.icon.small}
        height={theme.dimensions.icon.small}
        color="info50"
      />
      <H200BoldDiv>{t("box-info")}</H200BoldDiv>
    </Container>
  );
};

const Container = styled(P14Div)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.dimensions.boxLegendGap}px;
`;

export default InfoLegend;
