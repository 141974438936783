import { Outlet } from "react-router";
import Menu from "components/menu";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Layout = () => {
  const { t } = useTranslation();
  const TRANSLATION_PREFIX = "LMSUserGuide.adminschool.menu.items";

  const menuItems = [
    {
      id: "roles",
      title: t(`${TRANSLATION_PREFIX}.roles.title`),
      subitems: [],
    },
    {
      id: "timetable",
      title: t(`${TRANSLATION_PREFIX}.timetable.title`),
      subitems: [
        {
          id: "sub-1",
          title: t(`${TRANSLATION_PREFIX}.timetable.subtitles.1`),
          subtitle: "",
        },
        {
          id: "sub-2",
          title: t(`${TRANSLATION_PREFIX}.timetable.subtitles.2`),
          subtitle: "",
        },
        {
          id: "sub-3",
          title: t(`${TRANSLATION_PREFIX}.timetable.subtitles.3`),
          subtitle: "",
        },
      ],
    },
    {
      id: "classrooms",
      title: t(`${TRANSLATION_PREFIX}.classrooms.title`),
      subitems: [],
    },
    {
      id: "users",
      title: t(`${TRANSLATION_PREFIX}.users.title`),
      subitems: [
        {
          id: "sub-1",
          title: t(`${TRANSLATION_PREFIX}.users.subtitles.1`),
          subtitle: "",
        },
        {
          id: "sub-1",
          title: t(`${TRANSLATION_PREFIX}.users.subtitles.2`),
          subtitle: "",
        },
        {
          id: "sub-1",
          title: t(`${TRANSLATION_PREFIX}.users.subtitles.3`),
          subtitle: "",
        },
      ],
    },
    {
      id: "resources",
      title: t(`${TRANSLATION_PREFIX}.resources.title`),
      subitems: [],
    },
  ];

  return (
    <Container>
      <Menu items={menuItems} defaultPage={"roles"} />
      <Outlet />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.background.color};
`;

export default Layout;
