import styled from "styled-components";

export default function Video({ src, center, ...props }) {
  return (
    <Container center={center}>
      <StyledVideo {...props}>
        <Source src={src} type="video/mp4" />
      </StyledVideo>
    </Container>
  );
}

const StyledVideo = styled(({ height, width, margin, maxWidth, ...props }) => (
  <video {...props} />
))`
  ${({ height, width, margin, maxWidth }) =>
    `height: ${height};width: ${width}; margin:${margin};max-width:${maxWidth};`}
`;
const Source = styled.source``;

const Container = styled.div`
  display: flex;
  ${({ center }) => (!!center ? "justify-content:center;" : "")}
`;
