import { useEffect } from "react";
import { useSlice } from "slices";

export const useScrollEnd = (ref) => {
  const [isProgrammaticScroll, setIsProgrammaticScroll] = useSlice(
    "isProgrammaticScroll"
  );

  useEffect(() => {
    const node = ref.current;
    const handleScrollEnd = () => {
      if (isProgrammaticScroll) {
        node.scrollBy({ top: -52, behavior: "smooth" });
        setIsProgrammaticScroll(false);
      }
    };
    node.addEventListener("scrollend", handleScrollEnd);
    return () => {
      node.removeEventListener("scrollend", handleScrollEnd);
    };
  }, [isProgrammaticScroll]);
};
