import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import { useScroll } from "hooks";
import P16Div from "components/page/p16-div";
import { useTheme } from "styled-components";
import Subtitle from "components/page/subtitle";
import P16BoldSpan from "components/page/p16-bold-span";
import Ol from "components/page/ol";
import P16ListItem from "components/page/p16-list-item";
import Image from "components/page/image";
import img0 from "../../../assets/tracking-tool-guide/first-steps/0.png";
import img0Ca from "../../../assets/tracking-tool-guide/first-steps/0_ca.png";
import img1 from "../../../assets/tracking-tool-guide/first-steps/1.png";
import img2 from "../../../assets/tracking-tool-guide/first-steps/2.png";
import img2Ca from "../../../assets/tracking-tool-guide/first-steps/1_ca.png";
import img3 from "../../../assets/tracking-tool-guide/first-steps/3.png";
import img3Ca from "../../../assets/tracking-tool-guide/first-steps/2_ca.png";
import img4 from "../../../assets/tracking-tool-guide/first-steps/4.png";
import img4Ca from "../../../assets/tracking-tool-guide/first-steps/3_ca.png";
import img5 from "../../../assets/tracking-tool-guide/first-steps/5.png";
import img5Ca from "../../../assets/tracking-tool-guide/first-steps/4_ca.png";
import Box from "components/page/box";
import WarningLegend from "components/page/warning-legend";
import P14Div from "components/page/p14-div";
import Span from "components/page/span";

const PAGE_TRANSLATIONS_PREFIX =
  "trackingToolGuide.teacher.pages-content.first-steps";

const FirstSteps = () => {
  const { i18n, t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  const theme = useTheme();

  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title`)}
      </H600BlackTitle>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.0`)}
      </Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.0.1`)}
      </P16Div>
      <Image
        src={i18n.language === "ca" ? img0Ca : img0}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.0.2`)}
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[2] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.1`)}
      </Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1`)}
      </P16Div>
      <Image
        src={img1}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2`)}
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[3] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.2`)}
      </Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3`)}
      </P16Div>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4`)}
      </P16Div>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5`)}</P16BoldSpan>
        <Ol>
          <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1`)}</P16ListItem>
        </Ol>
      </P16Div>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6`)}</P16BoldSpan>
        <Ol start="2">
          <P16ListItem margin="0 0 20px">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2`)}
          </P16ListItem>
          <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3`)}</P16ListItem>
        </Ol>
      </P16Div>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7`)}</P16BoldSpan>
        <Ol start="4">
          <P16ListItem margin="0 0 20px">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4`)}
          </P16ListItem>
          <P16ListItem margin="0 0 20px">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.5`)}
          </P16ListItem>
          <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.6`)}</P16ListItem>
        </Ol>
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[4] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.3`)}
      </Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.8`)}
      </P16Div>
      <Image
        src={i18n.language === "ca" ? img2Ca : img2}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <Image
        src={i18n.language === "ca" ? img3Ca : img3}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[5] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.4`)}
      </Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.9`)}
      </P16Div>
      <Image
        src={i18n.language === "ca" ? img4Ca : img4}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <Box type="warning" margin="20px 30px">
        <WarningLegend />
        <P14Div color="warning80">
          {t(`${PAGE_TRANSLATIONS_PREFIX}.box.1`)}
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[6] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.5`)}
      </Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10.2`)}</P16BoldSpan>
      </P16Div>
      <Box type="warning" margin="20px 30px">
        <WarningLegend />
        <P14Div color="warning80">
          {t(`${PAGE_TRANSLATIONS_PREFIX}.box.2`)}
        </P14Div>
      </Box>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.11`)}
      </P16Div>
      <Image
        src={i18n.language === "ca" ? img5Ca : img5}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.12`)}
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[7] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.6`)}
      </Subtitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.13`)}
      </P16Div>
      <P16Div height={600} />
    </PageContainer>
  );
};

export default FirstSteps;
