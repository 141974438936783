import { useRef } from "react";
import {
  useSetSectionsRefs,
  useScrollspy,
  useSetActiveSectionIndex,
} from "hooks";

export const useScroll = () => {
  const sectionsRefs = useRef([]);
  const containerRef = useRef(null);
  useSetSectionsRefs(sectionsRefs);
  const activeSection = useScrollspy({
    scrollingElementRef: containerRef,
    sectionElementsRef: sectionsRefs,
  });
  useSetActiveSectionIndex(activeSection);
  return { sectionsRefs, containerRef };
};
