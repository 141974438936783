import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import P16Div from "components/page/p16-div";
import Span from "components/page/span";
import P16BoldSpan from "components/page/p16-bold-span";
import IFrame from "components/page/iframe";
import { sesionesAgendaIFrame, sesionesFinalizadaIFrame } from "iframes";
import { useScroll, useScrollEnd } from "hooks";
import Ul from "components/page/ul";
import P16ListItem from "components/page/p16-list-item";
import IconDescription from "components/page/icon-description";
import { pdf, word } from "icons-names";
import H300BoldDiv from "components/page/h300-bold-div";
import styled, { useTheme } from "styled-components";
import Box from "components/page/box";
import InfoLegend from "components/page/info-legend";
import P14Div from "components/page/p14-div";
import P14BoldSpan from "components/page/p14-bold-span";
import Row from "components/page/row";
import ToggleButton from "components/page/toggle-button";
import P12Div from "components/page/p12-div";
import Column from "components/page/column";
import Subtitle from "components/page/subtitle";
import macSVG from "assets/ic_desktop-outline.svg";
import Video from "components/page/video";
import video1 from "assets/lms-user-guide/adminschool/001.mov";
import video1Ca from "assets/lms-user-guide/adminschool/001_ca.mov";
import video6 from "assets/lms-user-guide/adminschool/006.mov";
import video6Ca from "assets/lms-user-guide/adminschool/006_ca.mov";
import WarningLegend from "components/page/warning-legend";
import Image from "components/page/image";
import img1 from "assets/lms-user-guide/adminschool/Calendario.svg";
import img1Ca from "assets/lms-user-guide/adminschool/Calendari.cat.svg";
import Button from "components/page/button";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.adminschool.pages-content.timetable";

const Timetable = () => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  useScrollEnd(containerRef);

  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
        position="sticky"
        top={0}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title`)}
      </H600BlackTitle>
      <Box type="info" margin={`${theme.dimensions.box.margin}px`}>
        <InfoLegend />
        <P14Div color="info80">
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.1`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.2`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.3`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.4`)}</Span>
        </P14Div>
      </Box>
      <P16Div margin="0 0 50px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.1`)}</Span>
        <ImageButtonContainer>
          <Image
            src={i18n.language === "ca" ? img1Ca : img1}
            isWithoutContainer
            maxWidth={theme.image.button.maxWidth}
          />
        </ImageButtonContainer>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.3`)}</Span>
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
        margin="0 0 30px"
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.1`)}
      </Subtitle>
      <P16Div margin="0 0 50px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3.3`)}</Span>
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[2] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.2`)}
      </Subtitle>
      <P16Div margin="0 0 30px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4.3`)}</Span>
      </P16Div>
      <Video
        src={i18n.language === "ca" ? video1Ca : video1}
        controls
        maxWidth={theme.video.maxWidth}
        center
        margin="0 0 50px"
      />
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[3] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.3`)}
      </Subtitle>
      <P16Div margin="0 0 30px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.2`)}</P16BoldSpan>
      </P16Div>
      <Video
        src={i18n.language === "ca" ? video6Ca : video6}
        controls
        maxWidth={theme.video.maxWidth}
        center
        margin="0 0 50px"
      />
      <Box
        type="warning"
        margin={`0 ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px  ${theme.dimensions.box.margin}px`}
      >
        <WarningLegend />
        <P14Div color="warning80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.1`)}</Span>
          <P14BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.2`)}</P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.3`)}</Span>
        </P14Div>
      </Box>
      <P16Div height={40} />
    </PageContainer>
  );
};

const ImageButtonContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  --max-width: ${({ theme }) => theme.image.button.container.maxWidth};
`;

export default Timetable;
