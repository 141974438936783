import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import P16Div from "components/page/p16-div";
import Span from "components/page/span";
import P16BoldSpan from "components/page/p16-bold-span";
import IFrame from "components/page/iframe";
import { sesionesAgendaIFrame, sesionesFinalizadaIFrame } from "iframes";
import { useScroll } from "hooks";
import Ul from "components/page/ul";
import P16ListItem from "components/page/p16-list-item";
import IconDescription from "components/page/icon-description";
import { pdf, word } from "icons-names";
import H300BoldDiv from "components/page/h300-bold-div";
import { useTheme } from "styled-components";
import Box from "components/page/box";
import InfoLegend from "components/page/info-legend";
import P14Div from "components/page/p14-div";
import P14BoldSpan from "components/page/p14-bold-span";
import Row from "components/page/row";
import ToggleButton from "components/page/toggle-button";
import P12Div from "components/page/p12-div";
import Column from "components/page/column";
import Subtitle from "components/page/subtitle";
import macSVG from "assets/ic_desktop-outline.svg";
import Ol from "components/page/ol";
import Image from "components/page/image";
import P14ListItem from "components/page/p14-list-item";
import img1 from "assets/lms-user-guide/adminschool/roles.svg";

const PAGE_TRANSLATIONS_PREFIX = "LMSUserGuide.adminschool.pages-content.roles";

const Roles = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();

  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
        position="sticky"
        top={0}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.1`)}
      </H600BlackTitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.4`)}</P16BoldSpan>
      </P16Div>
      <H600BlackTitle>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.2`)}
      </H600BlackTitle>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2`)}
      </P16Div>
      <Ul margin="0 0 30px">
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.1`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.2`)}</P16BoldSpan>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.1`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2.2`)}</P16BoldSpan>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3.1`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3.2`)}</P16BoldSpan>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.1`)}</Span>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4.2`)}</P16BoldSpan>
        </P16ListItem>
      </Ul>
      <Image
        src={img1}
        alt="image for roles"
        maxWidth={theme.image.maxWidth}
        width="350px"
        center
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
      />
      <Box type="info" margin={`${theme.dimensions.box.margin}px`}>
        <InfoLegend />
        <P14Div color="info80">{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3`)}</P14Div>
        <Ol>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.2`)}</Span>
          </P14ListItem>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.2.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.2.2`)}</Span>
          </P14ListItem>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.3.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.3.2`)}</Span>
          </P14ListItem>
          <P14ListItem color="info80">
            <P14BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.4.1`)}
            </P14BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.4.2`)}</Span>
          </P14ListItem>
        </Ol>
      </Box>
      {/* <P16Div height={440} /> */}
    </PageContainer>
  );
};

export default Roles;
