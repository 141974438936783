import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSlice } from "slices";
import H200RegularDiv from "components/page/h200-regular-div";
import H200BoldDiv from "components/page/h200-bold-div";
import { handleScroll } from "utils";
import Icon from "components/page/icon";
import { chevronDown, chevronLeft, chevronRight } from "icons-names";

const MenuItem = ({ id, title, subitems, selectedId, setSelectedId, page }) => {
  const navigate = useNavigate();
  const [activeSectionIndex] = useSlice("activeSectionIndex");
  const [sectionsRefs] = useSlice("sectionsRefs");
  const [, setIsProgrammaticScroll] = useSlice("isProgrammaticScroll");
  const theme = useTheme();

  const activateScroll = (index) => {
    setIsProgrammaticScroll(true);
    handleScroll(sectionsRefs[index], index);
  };

  const onTitleClick = () => {
    setSelectedId(id);
    page === id ? activateScroll(0) : navigate(id);
  };

  const onSubitemClick = (index) => {
    activateScroll(index);
  };

  const isSelected = selectedId === id;

  return (
    <>
      <TitleContainer isSelected={isSelected} onClick={onTitleClick}>
        {isSelected ? (
          <H200BoldDiv color="white">{title}</H200BoldDiv>
        ) : (
          <H200RegularDiv color="neutral90">{title}</H200RegularDiv>
        )}
        {isSelected && !subitems.length && (
          <StyledIcon
            name={chevronRight}
            width={theme.dimensions.icon.tiny}
            height={theme.dimensions.icon.tiny}
            color="white"
          />
        )}
        {!!subitems.length && !isSelected && (
          <StyledIcon
            name={chevronLeft}
            width={theme.dimensions.icon.tiny}
            height={theme.dimensions.icon.tiny}
            color="neutral90"
          />
        )}
        {!!subitems.length && isSelected && (
          <StyledIcon
            name={chevronDown}
            width={theme.dimensions.icon.tiny}
            height={theme.dimensions.icon.tiny}
            color="white"
          />
        )}
      </TitleContainer>
      {!!subitems.length &&
        isSelected &&
        subitems.map(({ id, title, subtitle }, index) => (
          <SubitemContainer
            key={id}
            onClick={() => onSubitemClick(index + 1)}
            isActive={activeSectionIndex === index + 1}
          >
            <div>
              <H200RegularDiv
                color={activeSectionIndex === index + 1 ? "white" : "neutral80"}
              >
                {title}
              </H200RegularDiv>
              {!!subtitle.length && (
                <H200RegularDiv
                  color={
                    activeSectionIndex === index + 1 ? "white" : "neutral80"
                  }
                >
                  {subtitle}
                </H200RegularDiv>
              )}
            </div>
            {activeSectionIndex === index + 1 && (
              <StyledIcon
                name={chevronRight}
                width={theme.dimensions.icon.tiny}
                height={theme.dimensions.icon.tiny}
                color="white"
              />
            )}
          </SubitemContainer>
        ))}
    </>
  );
};

//prettier-ignore
const TitleContainer = styled(({ isSelected, ...props }) => <div {...props} />)`
  ${({theme:{menuItem:{titleContainer:{padding:{top,bottom,left,right}}}}})=>`padding: ${top}px ${right}px ${bottom}px ${left}px;`}
  height: ${({theme})=>theme.menuItem.titleContainer.height}px;
  display: flex;
  align-items:center;
  gap:10px;
  ${({ isSelected, theme }) => isSelected ? `background-color:${theme.colors.jmcBlue};` : ""}
  cursor:pointer;
`;

//prettier-ignore
const SubitemContainer = styled(({ isActive, ...props }) => <div {...props} />)`
  ${({ isActive,theme }) => (isActive ? `background-color:${theme.colors.jmcBlue5};` : "")}
  cursor: pointer;
  display: flex;
  align-items:center;
  gap:10px;
  ${({theme:{menuItem:{subitemContainer:{padding:{left,right,top,bottom}}}}})=>`padding: ${top}px ${right}px ${bottom}px ${left}px;`}
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;

export default MenuItem;
