import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import { useScroll } from "hooks";
import H600BlackTitle from "components/page/h600-black-title";
import P16Div from "components/page/p16-div";
import P16BoldSpan from "components/page/p16-bold-span";
import Span from "components/page/span";
// import Ul from "components/page/ul";
import P16ListItem from "components/page/p16-list-item";
import Button from "components/page/button";
import H100BoldDiv from "components/page/h100-bold-div";
import H200BoldDiv from "components/page/h200-bold-div";
import Box from "components/page/box";
import P14Div from "components/page/p14-div";
import WarningLegend from "components/page/warning-legend";
import InfoLegend from "components/page/info-legend";
import BoxIconDescription from "components/page/box-icon-description";
import { arrowBack, arrowForward } from "icons-names";
import IFrame from "components/page/iframe";
import { planificaIFrame } from "iframes";
import P14BoldSpan from "components/page/p14-bold-span";
import Subtitle from "components/page/subtitle";
import { useTheme } from "styled-components";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.teacher.pages-content.teacher.planifica";

const Planifica = () => {
  const { t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  const theme = useTheme();

  return (
    <PageContainer ref={containerRef} color="neutral90">
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title-1`)}
      </H600BlackTitle>
      <P16Div>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.1`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.2`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.3`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.4`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.5`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.6`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.7`)}</P16BoldSpan>
        <Button margin="0px 10px">
          <H100BoldDiv>{t(`${PAGE_TRANSLATIONS_PREFIX}.l1.1.3`)}</H100BoldDiv>
        </Button>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p1.8`)}</Span>
      </P16Div>
      {/* <Ul>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l1.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l1.1.2`)}</Span>
          <Button margin="0px 10px">
            <H100BoldDiv>{t(`${PAGE_TRANSLATIONS_PREFIX}.l1.1.3`)}</H100BoldDiv>
          </Button>
        </P16ListItem>
      </Ul> */}
      <Box margin="50px 30px" type="warning">
        <WarningLegend />
        <P14Div color="warning80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.3`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-1.1.4`)}
          </P14BoldSpan>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title-2`)}
      </Subtitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p2`)}</Span>
      </P16Div>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p3.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p3.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p3.3`)}</Span>
      </P16Div>
      <Box margin="50px 30px" type="info">
        <InfoLegend />
        <P14Div color="info80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-2.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-2.1.2`)}
          </P14BoldSpan>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[2] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title-3`)}
      </Subtitle>
      <P16Div margin="0px 0px 50px 0px">
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p4.1`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p4.2`)}</Span>
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[3] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title-4`)}
      </Subtitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p5.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p5.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p5.3`)}</Span>
      </P16Div>
      <Box margin="50px 30px" type="warning">
        <WarningLegend />
        <P14Div color="warning80" margin="0 0 10px">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-3.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-3.1.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-3.1.3`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-3.1.4`)}
          </P14BoldSpan>
        </P14Div>
        <P14Div color="warning80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-3.2`)}</Span>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[4] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title-5`)}
      </Subtitle>
      <P16Div margin="0px 0px 30px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p6`)}</Span>
      </P16Div>
      <Box margin="50px 30px" type="warning">
        <WarningLegend />
        <P14Div color="warning80" margin="0 0 10px">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-6.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-6.1.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-6.1.3`)}</Span>
        </P14Div>
        <P14Div color="warning80" margin="0 0 10px">
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-6.2.1`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-6.2.2`)}</Span>
        </P14Div>
        <P14Div color="warning80">
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-6.3.1`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-6.3.2`)}</Span>
        </P14Div>
      </Box>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p7.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p7.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p7.3`)}</Span>
      </P16Div>
      <Box margin="50px 30px" type="info">
        <InfoLegend />
        <P14Div color="info80">
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-4.1.1`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-4.1.2`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-4.1.3`)}
          </P14BoldSpan>
        </P14Div>
      </Box>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p8.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p8.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p8.3`)}</Span>
      </P16Div>
      <BoxIconDescription
        iconName={arrowForward}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l3.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l3.1.2`)}</Span>
          </P16ListItem>
        }
        margin="20px 0 40px"
        boxWidth={theme.dimensions.boxIconDescription.width}
        boxHeight={theme.dimensions.boxIconDescription.height}
      />
      <BoxIconDescription
        iconName={arrowBack}
        listItem={
          <P16ListItem>
            <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l4.1.1`)}</P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.l4.1.2`)}</Span>
          </P16ListItem>
        }
        margin="0 0 50px"
        boxWidth={theme.dimensions.boxIconDescription.width}
        boxHeight={theme.dimensions.boxIconDescription.height}
      />
      <IFrame
        {...planificaIFrame}
        margin={`50px ${theme.dimensions.iFrame.marginHorizontal}px 0 ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <Box margin="50px 30px" type="warning">
        <WarningLegend />
        <P14Div color="warning80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.box-5.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.box-5.1.2`)}
          </P14BoldSpan>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[5] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title-6`)}
      </Subtitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.p9`)}</Span>
      </P16Div>
      <P16Div>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p10.1`)}</P16BoldSpan>
        <Button margin="0 0 0 10px" color="primary50" type="button2">
          <H200BoldDiv color="white">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.l2.1.2`)}
          </H200BoldDiv>
        </Button>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.p10.2`)}</P16BoldSpan>
      </P16Div>
      {/* <Ul>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.l2.1.1`)}</P16BoldSpan>
          <Button margin="0px 10px" color="primary50" type="button2">
            <H200BoldDiv color="white">
              {t(`${PAGE_TRANSLATIONS_PREFIX}.l2.1.2`)}
            </H200BoldDiv>
          </Button>
        </P16ListItem>
      </Ul> */}
      <P16Div height={600} />
    </PageContainer>
  );
};

export default Planifica;
