import styled from "styled-components";
import { colorMixin } from "cssmixins";

const StyledSpan = styled.span`
  ${colorMixin}
`;

const Span = (props) => <StyledSpan {...props} />;

export default Span;
