import { Outlet } from "react-router";
import Menu from "components/menu";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TRANSLATION_PREFIX = "trackingToolGuide.teacher.menu.items";

const Layout = () => {
  const { t } = useTranslation();

  const menuItems = [
    {
      id: "first-questions",
      title: t(`${TRANSLATION_PREFIX}.first-questions.index`),
      subitems: [],
    },
    {
      id: "first-steps",
      title: t(`${TRANSLATION_PREFIX}.first-steps.index`),
      subitems: [
        {
          id: "step-0",
          title: t(`${TRANSLATION_PREFIX}.first-steps.subtitles.0`),
          subtitle: "",
        },
        {
          id: "step-1",
          title: t(`${TRANSLATION_PREFIX}.first-steps.subtitles.1`),
          subtitle: "",
        },
        {
          id: "step-2",
          title: t(`${TRANSLATION_PREFIX}.first-steps.subtitles.2`),
          subtitle: "",
        },
        {
          id: "step-3",
          title: t(`${TRANSLATION_PREFIX}.first-steps.subtitles.3`),
          subtitle: "",
        },
        {
          id: "step-4",
          title: t(`${TRANSLATION_PREFIX}.first-steps.subtitles.4`),
          subtitle: "",
        },
        {
          id: "step-5",
          title: t(`${TRANSLATION_PREFIX}.first-steps.subtitles.5`),
          subtitle: "",
        },
        {
          id: "step-6",
          title: t(`${TRANSLATION_PREFIX}.first-steps.subtitles.6`),
          subtitle: "",
        },
      ],
    },
    {
      id: "general",
      title: t(`${TRANSLATION_PREFIX}.general.index`),
      subitems: [
        {
          id: "1",
          title: t(`${TRANSLATION_PREFIX}.general.subtitles.1`),
          subtitle: "",
        },
        {
          id: "2",
          title: t(`${TRANSLATION_PREFIX}.general.subtitles.2`),
          subtitle: "",
        },
        {
          id: "3",
          title: t(`${TRANSLATION_PREFIX}.general.subtitles.3`),
          subtitle: "",
        },
        {
          id: "4",
          title: t(`${TRANSLATION_PREFIX}.general.subtitles.4`),
          subtitle: "",
        },
        {
          id: "5",
          title: t(`${TRANSLATION_PREFIX}.general.subtitles.5`),
          subtitle: "",
        },
        {
          id: "6",
          title: t(`${TRANSLATION_PREFIX}.general.subtitles.6`),
          subtitle: "",
        },
      ],
    },
    // {
    //   id: "planning",
    //   title: t(`${TRANSLATION_PREFIX}.planning.index`),
    //   subitems: [
    //     {
    //       id: "1",
    //       title: t(`${TRANSLATION_PREFIX}.planning.subtitles.1`),
    //       subtitle: "",
    //     },
    //     {
    //       id: "2",
    //       title: t(`${TRANSLATION_PREFIX}.planning.subtitles.2`),
    //       subtitle: "",
    //     },
    //   ],
    // },
    // {
    //   id: "evaluation",
    //   title: t(`${TRANSLATION_PREFIX}.evaluation.index`),
    //   subitems: [
    //     {
    //       id: "1",
    //       title: t(`${TRANSLATION_PREFIX}.evaluation.subtitles.1`),
    //       subtitle: "",
    //     },
    //     {
    //       id: "2",
    //       title: t(`${TRANSLATION_PREFIX}.evaluation.subtitles.2`),
    //       subtitle: "",
    //     },
    //     {
    //       id: "3",
    //       title: t(`${TRANSLATION_PREFIX}.evaluation.subtitles.3`),
    //       subtitle: "",
    //     },
    //   ],
    // },
    // {
    //   id: "reports-ces",
    //   title: t(`${TRANSLATION_PREFIX}.reports-ces.index`),
    //   subitems: [
    //     {
    //       id: "1",
    //       title: t(`${TRANSLATION_PREFIX}.reports-ces.subtitles.1`),
    //       subtitle: "",
    //     },
    //   ],
    // },
    // {
    //   id: "reports-ies",
    //   title: t(`${TRANSLATION_PREFIX}.reports-ies.index`),
    //   subitems: [
    //     {
    //       id: "1",
    //       title: t(`${TRANSLATION_PREFIX}.reports-ies.subtitles.1`),
    //       subtitle: "",
    //     },
    //   ],
    // },
  ];

  return (
    <Container>
      <Menu items={menuItems} defaultPage={"first-questions"} />
      <Outlet />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.background.color};
`;

export default Layout;
