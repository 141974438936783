import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import P16Div from "components/page/p16-div";
import Span from "components/page/span";
import P16BoldSpan from "components/page/p16-bold-span";
import IFrame from "components/page/iframe";
import { sesionesAgendaIFrame, sesionesFinalizadaIFrame } from "iframes";
import { useScroll } from "hooks";
import Ul from "components/page/ul";
import P16ListItem from "components/page/p16-list-item";
import IconDescription from "components/page/icon-description";
import { pdf, word } from "icons-names";
import H300BoldDiv from "components/page/h300-bold-div";
import { useTheme } from "styled-components";
import Box from "components/page/box";
import InfoLegend from "components/page/info-legend";
import P14Div from "components/page/p14-div";
import P14BoldSpan from "components/page/p14-bold-span";
import Row from "components/page/row";
import ToggleButton from "components/page/toggle-button";
import P12Div from "components/page/p12-div";
import Column from "components/page/column";
import Subtitle from "components/page/subtitle";
import macSVG from "assets/ic_desktop-outline.svg";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.teacher.pages-content.teacher.sesiones";

const Sesiones = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.1`)}
      </H600BlackTitle>
      <P16Div margin="0 0 30px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.3`)}</Span>
      </P16Div>
      <IFrame
        {...sesionesAgendaIFrame}
        margin={`0 ${theme.dimensions.iFrame.marginHorizontal}px 70px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.2`)}
      </Subtitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3`)}</Span>
      </P16Div>
      <Ul>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.3`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.4`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.5`)}</Span>
        </P16ListItem>
      </Ul>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4`)}</Span>
      </P16Div>
      <IconDescription
        iconName={macSVG}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.3`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.4`)}</Span>
          </P16ListItem>
        }
        margin="30px 0 50px"
        isSVG
      />
      <IconDescription
        iconName={pdf}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.3`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.4`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.5`)}
            </P16BoldSpan>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={word}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1.3`)}
            </P16BoldSpan>
          </P16ListItem>
        }
        margin="0 0 30px"
      />
      <P16Div margin="0 0 50px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5`)}</Span>
      </P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[2] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.3`)}
      </Subtitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.5`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.6`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.7`)}</Span>
      </P16Div>
      <IFrame
        {...sesionesFinalizadaIFrame}
        margin={`20px ${theme.dimensions.iFrame.marginHorizontal}px 30px ${theme.dimensions.iFrame.marginHorizontal}px`}
      />
      <Box type="info" margin="0 30px 50px 30px">
        <InfoLegend />
        <P14Div color="info80">
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.1`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.2`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.3`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.4`)}</Span>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[3] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.4`)}
      </Subtitle>
      <P16Div margin="0 0 20px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.8`)}</Span>
      </P16Div>
      <Row justifyContent="center">
        <Column gap={10}>
          <Row gap={180}>
            <H300BoldDiv>{t(`${PAGE_TRANSLATIONS_PREFIX}.hs.2`)}</H300BoldDiv>
            <ToggleButton width={35} />
          </Row>
          <P12Div color="neutral70">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.9`)}
          </P12Div>
        </Column>
      </Row>
      <Box type="info" margin="30px 30px 50px 30px">
        <InfoLegend />
        <P14Div color="info80">
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.2.1.1`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.2.1.2`)}</Span>
        </P14Div>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[4] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.5`)}
      </Subtitle>
      <P16Div margin="0 0 20px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.10.5`)}</Span>
      </P16Div>
      <Box type="info" margin="30px 30px 50px 30px">
        <InfoLegend />
        <P14Div color="info80">
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.3.1.1`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.3.1.2`)}</Span>
        </P14Div>
      </Box>
      <P16Div height={440} />
    </PageContainer>
  );
};

export default Sesiones;
