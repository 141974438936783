import H600BlackTitle from "components/page/h600-black-title";
import IconDescription from "components/page/icon-description";
import P16BoldSpan from "components/page/p16-bold-span";
import P16Div from "components/page/p16-div";
import P16ListItem from "components/page/p16-list-item";
import PageContainer from "components/page/page-container";
import Span from "components/page/span";
import { useScroll } from "hooks";
import { pdf, word } from "icons-names";
import { useTranslation } from "react-i18next";
import macSVG from "assets/ic_desktop-outline.svg";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.teacher.pages-content.teacher.recursos";

const Recursos = () => {
  const { sectionsRefs, containerRef } = useScroll();
  const { t } = useTranslation();
  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.1`)}
      </H600BlackTitle>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1.5`)}</Span>
      </P16Div>
      <IconDescription
        iconName={macSVG}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.3`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.4`)}</Span>
          </P16ListItem>
        }
        margin="30px 0 50px"
        isSVG
      />
      <IconDescription
        iconName={pdf}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.3`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.4`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.5`)}
            </P16BoldSpan>
          </P16ListItem>
        }
        margin="0 0 50px"
      />
      <IconDescription
        iconName={word}
        listItem={
          <P16ListItem>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.1`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.2`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.3`)}
            </P16BoldSpan>
          </P16ListItem>
        }
        margin="0 0 30px"
      />
    </PageContainer>
  );
};

export default Recursos;
