import P14Div from "components/page/p14-div";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import H200BoldDiv from "./h200-bold-div";

const Legend = ({ icon, color, type }) => {
  const { t } = useTranslation();

  return (
    <Container color={color} margin="0 0 10px">
      {icon}
      <H200BoldDiv>{t(type)}</H200BoldDiv>
    </Container>
  );
};

const Container = styled(P14Div)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.dimensions.boxLegendGap}px;
`;

export default Legend;
