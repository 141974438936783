export const stopCircle = "ic:baseline-stop-circle";
export const edit = "akar-icons:edit";
export const mac = "ic:baseline-desktop-mac";
export const pdf = "bi:file-earmark-pdf";
export const spellCheck = "fa6-solid:spell-check";
export const notepadPerson = "fluent:notepad-person-24-regular";
export const textClock = "fluent:document-text-clock-20-regular";
export const calendar = "fluent:calendar-rtl-32-regular";
export const calendarClock = "fluent:calendar-clock-24-regular";
export const flashCheck = "fluent:flash-checkmark-20-regular";
export const arrowCheck = "fluent:checkbox-arrow-right-24-regular";
export const editPeople = "fluent:people-edit-20-regular";
export const key = "fluent:key-24-regular";
export const box = "carbon:box";
export const starCheck = "fluent:checkmark-starburst-16-regular";
export const personCheck = "fluent:person-available-20-regular";
export const arrowForward = "ion:arrow-forward-outline";
export const arrowBack = "ion:arrow-back-outline";
export const word = "bi:file-earmark-word";
export const filledRadioButton = "fluent:radio-button-24-filled";
export const chevronLeft = "akar-icons:chevron-left";
export const chevronDown = "akar-icons:chevron-down";
export const chevronRight = "akar-icons:chevron-right";
export const sidebarReverse = "bi:layout-text-sidebar-reverse";
export const add = "akar-icons:plus";
