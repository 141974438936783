import styled from "styled-components";
import { pageTitleMixin } from "cssmixins";
import { forwardRef } from "react";

const StyledTitle = styled(
  forwardRef(({ position, top, ...props }, ref) => <div {...props} ref={ref} />)
)`
  ${pageTitleMixin}
  ${({ position }) => (position ? `position:${position};` : "")}
  ${({ top }) => (top !== undefined ? `top:${top}px;` : "")}
`;

const H600BlackTitle = forwardRef((props, ref) => (
  <StyledTitle className="H600-Black" {...props} ref={ref} />
));

export default H600BlackTitle;
