import styled from "styled-components";
import { colorMixin } from "cssmixins";

const StyledSpan = styled.span`
  ${colorMixin}
`;

const P16BoldSpan = (props) => <StyledSpan className="P16-Bold" {...props} />;

export default P16BoldSpan;
