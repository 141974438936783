import { useTranslation } from "react-i18next";
import PageContainer from "components/page/page-container";
import H600BlackTitle from "components/page/h600-black-title";
import { useScroll } from "hooks";
import P16Div from "components/page/p16-div";
import { useTheme } from "styled-components";
import Subtitle from "components/page/subtitle";
import P16BoldSpan from "components/page/p16-bold-span";
import Ol from "components/page/ol";
import P16ListItem from "components/page/p16-list-item";
import Image from "components/page/image";
import img1 from "../../../assets/tracking-tool-guide/general/1.png";
import img1Ca from "../../../assets/tracking-tool-guide/general/5_ca.png";
import img1_2 from "../../../assets/tracking-tool-guide/planning/1.png";
import img1_2Ca from "../../../assets/tracking-tool-guide/planning/6_ca.png";
import img2_2 from "../../../assets/tracking-tool-guide/planning/2.png";
import img2_2Ca from "../../../assets/tracking-tool-guide/planning/7_ca.png";
import img3_2 from "../../../assets/tracking-tool-guide/planning/3.png";
import img3_2Ca from "../../../assets/tracking-tool-guide/planning/8_ca.png";
import img4_2 from "../../../assets/tracking-tool-guide/planning/4.png";
import img4_2Ca from "../../../assets/tracking-tool-guide/planning/9_ca.png";
import img5_2 from "../../../assets/tracking-tool-guide/planning/5.png";
import img5_2Ca from "../../../assets/tracking-tool-guide/planning/10_ca.png";
import img1_3 from "../../../assets/tracking-tool-guide/map/1.png";
import img1_3Ca from "../../../assets/tracking-tool-guide/map/11_ca.png";
import img1_4 from "../../../assets/tracking-tool-guide/evaluation/1.png";
import img1_4Ca from "../../../assets/tracking-tool-guide/evaluation/12_ca.png";
import img2_4 from "../../../assets/tracking-tool-guide/evaluation/2.png";
import img2_4Ca from "../../../assets/tracking-tool-guide/evaluation/COMENTARIO 1_ca.png";
import img3_4 from "../../../assets/tracking-tool-guide/evaluation/3.png";
import img3_4Ca from "../../../assets/tracking-tool-guide/evaluation/14_ca.png";
import img4_4 from "../../../assets/tracking-tool-guide/evaluation/4.png";
import img4_4Ca from "../../../assets/tracking-tool-guide/evaluation/13_ca.png";
import img1_5 from "../../../assets/tracking-tool-guide/reports-ces/1.png";
import img1_5Ca from "../../../assets/tracking-tool-guide/reports-ces/15_ca.png";
import img2_5 from "../../../assets/tracking-tool-guide/reports-ces/2.png";
import img2_5Ca from "../../../assets/tracking-tool-guide/reports-ces/23_ca.png";
import img3_5 from "../../../assets/tracking-tool-guide/reports-ces/3.png";
import img3_5Ca from "../../../assets/tracking-tool-guide/reports-ces/20_ca.png";
import img4_5 from "../../../assets/tracking-tool-guide/reports-ces/4.png";
import img4_5Ca from "../../../assets/tracking-tool-guide/reports-ces/16_ca.png";
import img5_5 from "../../../assets/tracking-tool-guide/reports-ces/5.png";
import img5_5Ca from "../../../assets/tracking-tool-guide/reports-ces/17_ca.png";
import img6_5 from "../../../assets/tracking-tool-guide/reports-ces/6.png";
import img6_5Ca from "../../../assets/tracking-tool-guide/reports-ces/18_ca.png";
import img7_5 from "../../../assets/tracking-tool-guide/reports-ces/7.png";
import img7_5Ca from "../../../assets/tracking-tool-guide/reports-ces/19_ca.png";
import img8_5 from "../../../assets/tracking-tool-guide/reports-ces/8.png";
import img8_5Ca from "../../../assets/tracking-tool-guide/reports-ces/21_ca.png";
import img9_5 from "../../../assets/tracking-tool-guide/reports-ces/image.png";
import img1_6 from "../../../assets/tracking-tool-guide/reports-ies/1.png";
import img1_6Ca from "../../../assets/tracking-tool-guide/reports-ies/22_ca.png";
import img1_6CaBis from "../../../assets/tracking-tool-guide/reports-ies/COMENTARIO 3_ca.png";
import img2_6 from "../../../assets/tracking-tool-guide/reports-ies/2.png";
import img3_6 from "../../../assets/tracking-tool-guide/reports-ies/3.png";
import img3_6Ca from "../../../assets/tracking-tool-guide/reports-ies/24_ca.png";
import img4_6 from "../../../assets/tracking-tool-guide/reports-ies/4.png";
import img4_6Ca from "../../../assets/tracking-tool-guide/reports-ies/25_ca.png";
import img4_6CaBis from "../../../assets/tracking-tool-guide/reports-ies/COMENTARIO 4_ca.png";
import B from "components/page/box";
import P14Div from "components/page/p14-div";
import Ul from "components/page/ul";
import P14ListItem from "components/page/p14-list-item";
import styled from "styled-components";
import Legend from "components/page/legend";
import ObjectiveIcon from "components/page/objective-icon";
import ListFieldsIcon from "components/page/list-fields-icon";
import MoreInfoLegend from "components/page/more-info-legend";
import InfoLegend from "components/page/info-legend";
import Span from "components/page/span";
import P14BoldSpan from "components/page/p14-bold-span";
import WarningLegend from "components/page/warning-legend";

const PAGE_TRANSLATIONS_PREFIX =
  "trackingToolGuide.teacher.pages-content.general";
const PAGE_TRANSLATIONS_PREFIX_2 =
  "trackingToolGuide.teacher.pages-content.planning";

const PAGE_TRANSLATIONS_PREFIX_3 =
  "trackingToolGuide.teacher.pages-content.map";

const PAGE_TRANSLATIONS_PREFIX_4 =
  "trackingToolGuide.teacher.pages-content.evaluation";
const PAGE_TRANSLATIONS_PREFIX_5 =
  "trackingToolGuide.teacher.pages-content.reports-ces";
const PAGE_TRANSLATIONS_PREFIX_6 =
  "trackingToolGuide.teacher.pages-content.reports-ies";

const General = () => {
  const { i18n, t } = useTranslation();
  const { sectionsRefs, containerRef } = useScroll();
  const theme = useTheme();

  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.title`)}
      </H600BlackTitle>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.subtitles.1`)}
      </Subtitle>
      <Image
        src={i18n.language === "ca" ? img1Ca : img1}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <BoxesContainer>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ObjectiveIcon color={theme.colors.pink60} />}
            color="pink90"
            type="objective"
          />
          <P14Div color="pink90">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1`)}
          </P14Div>
        </Box>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ListFieldsIcon color={theme.colors.pink60} />}
            color="pink90"
            type="list-fields"
          />
          <Ul color="pink90">
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.2.1`)}
            </P14ListItem>
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.2.2`)}
            </P14ListItem>
          </Ul>
        </Box>
      </BoxesContainer>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1`)}</P16BoldSpan>
      </P16Div>
      <Ol margin="0 0 20px">
        <P16ListItem margin="0 0 20px">
          {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1`)}
        </P16ListItem>
        <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.2`)}</P16ListItem>
      </Ol>
      <Box type="more-info" margin="20px 30px">
        <MoreInfoLegend />
        <Ul color="green90">
          <P16ListItem margin="0 0 20px">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1`)}
          </P16ListItem>
          <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.2`)}</P16ListItem>
        </Ul>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[2] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX_2}.subtitles.1`)}
      </Subtitle>
      <Image
        src={i18n.language === "ca" ? img1_2Ca : img1_2}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <BoxesContainer>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ObjectiveIcon color={theme.colors.pink60} />}
            type="objective"
            color="pink90"
          />
          <P14Div color="pink90">
            {t(`${PAGE_TRANSLATIONS_PREFIX_2}.boxes.1`)}
          </P14Div>
        </Box>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ListFieldsIcon color={theme.colors.pink60} />}
            type="list-fields"
            color="pink90"
          />
          <Ul color="pink90">
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX_2}.boxes.2.1`)}
            </P14ListItem>
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX_2}.boxes.2.2`)}
            </P14ListItem>
          </Ul>
        </Box>
      </BoxesContainer>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX_2}.ps.1`)}</P16BoldSpan>
      </P16Div>
      <Ol margin="0 0 20px">
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.1.1`)}
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img2_2Ca : img2_2}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.1.2`)}
          </P16Div>
          <ImagesContainer>
            <Image
              src={i18n.language === "ca" ? img3_2Ca : img3_2}
              maxWidth={theme.image.maxWidth}
              center
            />
            <Image
              src={i18n.language === "ca" ? img4_2Ca : img4_2}
              maxWidth={theme.image.maxWidth}
              center
            />
          </ImagesContainer>
          <Box type="info" margin="20px 30px">
            <InfoLegend />
            <P14Div color="info80">
              {t(`${PAGE_TRANSLATIONS_PREFIX_2}.boxes.3`)}
            </P14Div>
          </Box>
        </P16ListItem>
        <P16ListItem>
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.1.3.1`)}</Span>
            <P16BoldSpan color="green">
              {t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.1.3.2`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.1.3.3`)}</Span>
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img5_2Ca : img5_2}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
      </Ol>
      <Box type="more-info" margin="20px 30px">
        <MoreInfoLegend />
        <Ul color="green90">
          <P16ListItem margin="0 0 20px">
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.2.1.1`)}</Span>
            <P16BoldSpan color="green">
              {t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.2.1.2`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.2.1.3`)}</Span>
          </P16ListItem>
          <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX_2}.ls.2.2`)}</P16ListItem>
        </Ul>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[3] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX_3}.subtitles.1`)}
      </Subtitle>
      <Image
        src={i18n.language === "ca" ? img1_3Ca : img1_3}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <BoxesContainer>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ObjectiveIcon color={theme.colors.pink60} />}
            type="objective"
            color="pink90"
          />
          <P14Div color="pink90">
            {t(`${PAGE_TRANSLATIONS_PREFIX_3}.boxes.1`)}
          </P14Div>
        </Box>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ListFieldsIcon color={theme.colors.pink60} />}
            type="list-fields"
            color="pink90"
          />
          <P14Div
            color="pink90"
            margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
          >
            {t(`${PAGE_TRANSLATIONS_PREFIX_3}.boxes.2.1`)}
          </P14Div>
          <P14Div color="pink90">
            {t(`${PAGE_TRANSLATIONS_PREFIX_3}.boxes.2.2`)}
          </P14Div>
        </Box>
      </BoxesContainer>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX_3}.ps.1`)}</P16BoldSpan>
      </P16Div>
      <Box type="info" margin="20px 30px">
        <InfoLegend />
        <P14Div color="info80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_3}.boxes.3.1`)}</Span>
          <P14BoldSpan color="green">
            {t(`${PAGE_TRANSLATIONS_PREFIX_3}.boxes.3.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_3}.boxes.3.3`)}</Span>
        </P14Div>
      </Box>
      <Ol margin="0 0 20px">
        <P16ListItem margin="0 0 20px">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_3}.ls.1.1.1`)}</Span>
          <P16BoldSpan color="green">
            {t(`${PAGE_TRANSLATIONS_PREFIX_3}.ls.1.1.2`)}
          </P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_3}.ls.1.1.3`)}</Span>
        </P16ListItem>
        <P16ListItem margin="0 0 20px">
          {t(`${PAGE_TRANSLATIONS_PREFIX_3}.ls.1.2`)}
        </P16ListItem>
        <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX_3}.ls.1.3`)}</P16ListItem>
      </Ol>
      <Box type="more-info" margin="20px 30px">
        <MoreInfoLegend />
        <Ul color="green90">
          <P16ListItem>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_3}.ls.2.1.1`)}</Span>
            <P16BoldSpan color="green">
              {t(`${PAGE_TRANSLATIONS_PREFIX_3}.ls.2.1.2`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_3}.ls.2.1.3`)}</Span>
          </P16ListItem>
        </Ul>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[4] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX_4}.subtitles.1`)}
      </Subtitle>
      <Image
        src={i18n.language === "ca" ? img1_4Ca : img1_4}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <BoxesContainer>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ObjectiveIcon color={theme.colors.pink60} />}
            type="objective"
            color="pink90"
          />
          <P14Div color="pink90">
            {t(`${PAGE_TRANSLATIONS_PREFIX_4}.boxes.1`)}
          </P14Div>
        </Box>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ListFieldsIcon color={theme.colors.pink60} />}
            type="list-fields"
            color="pink90"
          />
          <Ul color="pink90">
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX_4}.boxes.2.1`)}
            </P14ListItem>
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX_4}.boxes.2.2`)}
            </P14ListItem>
          </Ul>
        </Box>
      </BoxesContainer>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX_4}.ps.1`)}</P16BoldSpan>
      </P16Div>
      <Ol margin="0 0 20px">
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_4}.ls.1.1`)}
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img2_4Ca : img2_4}
            maxWidth={theme.image.maxWidth}
            center
          />
          <Box type="info" margin="20px 30px">
            <InfoLegend />
            <P14Div color="info80">
              {t(`${PAGE_TRANSLATIONS_PREFIX_4}.boxes.3`)}
            </P14Div>
          </Box>
        </P16ListItem>
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_4}.ls.1.2.1`)}</Span>
            <P16BoldSpan color="green">
              {t(`${PAGE_TRANSLATIONS_PREFIX_4}.ls.1.2.2`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_4}.ls.1.2.3`)}</Span>
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img3_4Ca : img3_4}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
        <P16ListItem>
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_4}.ls.1.3`)}
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img4_4Ca : img4_4}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
      </Ol>
      <Box type="more-info" margin="20px 30px">
        <MoreInfoLegend />
        <Ul color="green90">
          <P16ListItem
            margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
          >
            {t(`${PAGE_TRANSLATIONS_PREFIX_4}.ls.2.1`)}
          </P16ListItem>
          <P16ListItem>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_4}.ls.2.2.1`)}</Span>
            <P16BoldSpan>
              {t(`${PAGE_TRANSLATIONS_PREFIX_4}.ls.2.2.2`)}
            </P16BoldSpan>
          </P16ListItem>
        </Ul>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[5] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX_5}.subtitles.1`)}
      </Subtitle>
      <Image
        src={i18n.language === "ca" ? img1_5Ca : img1_5}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <BoxesContainer>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ObjectiveIcon color={theme.colors.pink60} />}
            type="objective"
            color="pink90"
          />
          <P14Div color="pink90">
            {t(`${PAGE_TRANSLATIONS_PREFIX_5}.boxes.1`)}
          </P14Div>
        </Box>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ListFieldsIcon color={theme.colors.pink60} />}
            type="list-fields"
            color="pink90"
          />
          <Ul color="pink90">
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX_5}.boxes.2.1`)}
            </P14ListItem>
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX_5}.boxes.2.2`)}
            </P14ListItem>
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX_5}.boxes.2.3`)}
            </P14ListItem>
          </Ul>
        </Box>
      </BoxesContainer>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX_5}.ps.1`)}</P16BoldSpan>
      </P16Div>
      <Box type="info" margin="20px 30px">
        <InfoLegend />
        <P14Div color="info80">
          {t(`${PAGE_TRANSLATIONS_PREFIX_5}.boxes.3`)}
        </P14Div>
      </Box>
      <Ol margin="0 0 20px">
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.1.1`)}
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img2_5Ca : img2_5}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.1.2.1`)}</Span>
            <P16BoldSpan color="green">
              {t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.1.2.2`)}
            </P16BoldSpan>
            <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.1.2.3`)}</Span>
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img3_5Ca : img3_5}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.1.3`)}
          </P16Div>
          {i18n.language === "ca" ? (
            <Image src={img4_5Ca} maxWidth={theme.image.maxWidth} center />
          ) : (
            <ImagesContainer>
              <Image src={img4_5} maxWidth={theme.image.maxWidth} center />
              <Image src={img9_5} maxWidth={theme.image.maxWidth} center />
            </ImagesContainer>
          )}
        </P16ListItem>
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.1.4`)}
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img5_5Ca : img5_5}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.1.5`)}
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img6_5Ca : img6_5}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
        <P16ListItem>
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.1.6`)}
          </P16Div>
          <ImagesContainer>
            <Image
              src={i18n.language === "ca" ? img7_5Ca : img7_5}
              maxWidth={theme.image.maxWidth}
              center
            />
            <Image
              src={i18n.language === "ca" ? img8_5Ca : img8_5}
              maxWidth={theme.image.maxWidth}
              center
            />
          </ImagesContainer>
        </P16ListItem>
      </Ol>
      <Box type="more-info" margin="20px 30px">
        <MoreInfoLegend />
        <Ul color="green90">
          <P16ListItem
            margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
          >
            {t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.2.1`)}
          </P16ListItem>
          <P16ListItem>{t(`${PAGE_TRANSLATIONS_PREFIX_5}.ls.2.2`)}</P16ListItem>
        </Ul>
      </Box>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[6] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX_6}.subtitles.1`)}
      </Subtitle>
      <Image
        src={i18n.language === "ca" ? img1_6CaBis : img1_6}
        maxWidth={theme.image.maxWidth}
        margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
        center
      />
      <BoxesContainer>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ObjectiveIcon color={theme.colors.pink60} />}
            type="objective"
            color="pink90"
          />
          <P14Div color="pink90">
            {t(`${PAGE_TRANSLATIONS_PREFIX_6}.boxes.1`)}
          </P14Div>
        </Box>
        <Box type="objective" margin="20px 30px">
          <Legend
            icon={<ListFieldsIcon color={theme.colors.pink60} />}
            type="list-fields"
            color="pink90"
          />
          <Ul color="pink90">
            <P14ListItem>
              {t(`${PAGE_TRANSLATIONS_PREFIX_6}.boxes.2`)}
            </P14ListItem>
          </Ul>
        </Box>
      </BoxesContainer>
      <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX_6}.ps.1`)}</P16BoldSpan>
      </P16Div>
      <Ol margin="0 0 20px">
        <P16ListItem margin="0 0 20px">
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.1.1`)}
          </P16Div>
          <Image
            src={i18n.language === "ca" ? img2_5Ca : img2_6}
            maxWidth={theme.image.maxWidth}
            center
          />
        </P16ListItem>
        <P16ListItem>
          <P16Div margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}>
            {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.1.2`)}
          </P16Div>
          <Ol type="a">
            <P16ListItem margin="0 0 20px">
              <P16Div
                margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
              >
                {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.2.1`)}
              </P16Div>
              <Image
                src={i18n.language === "ca" ? img3_6Ca : img3_6}
                maxWidth={theme.image.maxWidth}
                center
              />
            </P16ListItem>
            <P16ListItem>
              <P16Div
                margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
              >
                {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.2.2`)}
              </P16Div>
              <Image
                src={i18n.language === "ca" ? img4_6CaBis : img4_6}
                maxWidth={theme.image.maxWidth}
                center
              />
            </P16ListItem>
          </Ol>
        </P16ListItem>
      </Ol>
      <Box type="warning" margin="20px 30px">
        <WarningLegend />
        <P14Div color="warning80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_6}.boxes.3.1`)}</Span>
          <P14BoldSpan color="green">
            {t(`${PAGE_TRANSLATIONS_PREFIX_6}.boxes.3.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_6}.boxes.3.3`)}</Span>
        </P14Div>
      </Box>
      <Box type="more-info" margin="20px 30px">
        <MoreInfoLegend />
        <Ul color="green90">
          <P16ListItem>
            <P16Div
              margin={`0 0 ${theme.dimensions.paragraph.margin.bottom}px`}
            >
              {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.3.1`)}
            </P16Div>
            <Ul>
              <P16ListItem margin="0 0 20px">
                <Span color="suspenso">
                  {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.4.1.1`)}
                </Span>
                <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.4.1.2`)}</Span>
              </P16ListItem>
              <P16ListItem margin="0 0 20px">
                <Span color="bien">
                  {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.4.2.1`)}
                </Span>
                <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.4.2.2`)}</Span>
              </P16ListItem>
              <P16ListItem margin="0 0 20px">
                <Span color="notable">
                  {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.4.3.1`)}
                </Span>
                <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.4.3.2`)}</Span>
              </P16ListItem>
              <P16ListItem>
                <Span color="sobresaliente">
                  {t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.4.4.1`)}
                </Span>
                <Span>{t(`${PAGE_TRANSLATIONS_PREFIX_6}.ls.4.4.2`)}</Span>
              </P16ListItem>
            </Ul>
          </P16ListItem>
        </Ul>
      </Box>
    </PageContainer>
  );
};

const BoxesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Box = styled(B)`
  flex: 1;
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export default General;
