import Box from "components/page/box";
import BoxIconDescription from "components/page/box-icon-description";
import Button from "components/page/button";
import H100BoldDiv from "components/page/h100-bold-div";
import H200BoldDiv from "components/page/h200-bold-div";
import H600BlackTitle from "components/page/h600-black-title";
import InfoLegend from "components/page/info-legend";
import Ol from "components/page/ol";
import P14BoldSpan from "components/page/p14-bold-span";
import P14Div from "components/page/p14-div";
import P16BoldSpan from "components/page/p16-bold-span";
import P16Div from "components/page/p16-div";
import P16ListItem from "components/page/p16-list-item";
import PageContainer from "components/page/page-container";
import Span from "components/page/span";
import Subtitle from "components/page/subtitle";
import Ul from "components/page/ul";
import WarningLegend from "components/page/warning-legend";
import { useScroll } from "hooks";
import { arrowForward } from "icons-names";
import { useTranslation } from "react-i18next";

const PAGE_TRANSLATIONS_PREFIX =
  "LMSUserGuide.teacher.pages-content.teacher.replanifica";

const Replanifica = () => {
  const { sectionsRefs, containerRef } = useScroll();
  const { t } = useTranslation();
  return (
    <PageContainer color="neutral90" ref={containerRef}>
      <H600BlackTitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[0] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.1`)}
      </H600BlackTitle>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.1`)}</P16Div>
      <P16Div>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.4`)}</P16BoldSpan>
        <Button margin="0 10px">
          <H100BoldDiv color="lime100">
            {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.2`)}
          </H100BoldDiv>
        </Button>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.2.5`)}</Span>
      </P16Div>
      {/* <Ul>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.1`)}</P16BoldSpan>
          <Button margin="0 30px">
            <H100BoldDiv color="lime100">
              {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.1.1.2`)}
            </H100BoldDiv>
          </Button>
        </P16ListItem>
      </Ul> */}
      <Box margin="30px 30px 50px 30px" type="warning">
        <WarningLegend />
        <P14Div color="warning80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.3`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.4`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.5`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.6`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.1.1.7`)}</Span>
        </P14Div>
      </Box>
      <P16Div margin="0 0 50px">{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.3`)}</P16Div>
      <Subtitle
        ref={(el) => {
          sectionsRefs.current = [...sectionsRefs.current];
          sectionsRefs.current[1] = el;
        }}
      >
        {t(`${PAGE_TRANSLATIONS_PREFIX}.titles.2`)}
      </Subtitle>
      <P16Div>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.4`)}</P16Div>
      <Box margin="30px 30px" type="info">
        <InfoLegend />
        <P14Div color="info80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.2.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.2.1.2`)}
          </P14BoldSpan>
        </P14Div>
      </Box>
      <P16Div margin="0 0 30px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.5.2`)}</P16BoldSpan>
      </P16Div>
      <P16Div margin="0 0 30px">
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.1`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.2`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.3`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.4`)}</P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.5`)}</Span>
        <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.6`)}</P16BoldSpan>
        <P16BoldSpan color="critical50">
          {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.7`)}
        </P16BoldSpan>
        <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ps.6.8`)}</Span>
      </P16Div>
      <Ol>
        <P16ListItem margin="0 0 20px">
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.1.2`)}</Span>
        </P16ListItem>
        <P16ListItem>
          <P16BoldSpan>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.2.1`)}</P16BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.2.2.2`)}</Span>
          <Ul margin="20px 0 0 0">
            <P16ListItem margin="0 0 20px">
              <P16Div margin="0 0 20px">
                <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.1`)}</Span>
                <Button margin="0 0 0 10px">
                  <H100BoldDiv color="lime100">
                    {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.2`)}
                  </H100BoldDiv>
                </Button>
                <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.1.3`)}</Span>
              </P16Div>
            </P16ListItem>
            <P16ListItem>
              <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.2.1`)}</Span>
              <BoxIconDescription
                iconName={arrowForward}
                boxHeight={24}
                margin="0 10px 0 10px"
                isDescription={false}
                isInline
              />
              <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.2.2`)}</Span>
              <Button margin="0 10px" color="primary50" type="button2">
                <H200BoldDiv color="white">
                  {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.1.2`)}
                </H200BoldDiv>
              </Button>
              <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.3.2.3`)}</Span>
              {/* <BoxIconDescription
                iconName={arrowForward}
                listItem={
                  <P16ListItem>
                    <P16BoldSpan>
                      {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.4.1`)}
                    </P16BoldSpan>
                  </P16ListItem>
                }
                boxHeight={24}
                margin="20px 0"
              />
              <P16Div margin="0 0 20px">
                {t(`${PAGE_TRANSLATIONS_PREFIX}.ps.7`)}
              </P16Div>
              <Ul>
                <P16ListItem margin="0 0 20px">
                  <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.1.1`)}</Span>
                  <Button margin="0 10px" color="primary50" type="button2">
                    <H200BoldDiv color="white">
                      {t(`${PAGE_TRANSLATIONS_PREFIX}.ls.5.1.2`)}
                    </H200BoldDiv>
                  </Button>
                </P16ListItem>
              </Ul> */}
            </P16ListItem>
          </Ul>
        </P16ListItem>
      </Ol>
      <Box type="info" margin="30px 30px">
        <InfoLegend />
        <P14Div color="info80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.3.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.3.1.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.3.1.3`)}</Span>
        </P14Div>
      </Box>
      <Box type="warning" margin="0 30px 30px 30px">
        <WarningLegend />
        <P14Div color="warning80">
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.4.1.1`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.4.1.2`)}
          </P14BoldSpan>
          <Span>{t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.4.1.3`)}</Span>
          <P14BoldSpan>
            {t(`${PAGE_TRANSLATIONS_PREFIX}.boxes.4.1.4`)}
          </P14BoldSpan>
        </P14Div>
      </Box>
    </PageContainer>
  );
};

export default Replanifica;
