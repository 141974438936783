import { useEffect } from "react";
import { useSlice } from "slices";

export const useSetSectionsRefs = (refs) => {
  const [, setSectionsRefs] = useSlice("sectionsRefs");

  useEffect(() => {
    setSectionsRefs(refs.current);
  }, [setSectionsRefs, refs]);
};
