import { css } from "styled-components";

export const colorMixin = css`
  ${({ theme, color }) => `color:${theme.colors[color]};`}
`;

export const pageContainerMixin = css`
  border: 1px solid ${({ theme }) => theme.background.color};
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: ${({ theme }) =>
    theme.dimensions.pageContainer.padding.right}px;
  ${colorMixin}
`;

export const pageTitleMixin = css`
  margin-bottom: ${({ theme }) => theme.dimensions.title.marginBottom}px;
  background-color: ${({ theme }) => theme.background.color};
  z-index: 9999;
  padding-bottom: 10px;
  ${colorMixin}
`;
