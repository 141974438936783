import styled from "styled-components";
import { colorMixin } from "cssmixins";

const DivStyled = styled.div`
  ${colorMixin}
`;

const H100BoldDiv = (props) => <DivStyled className="H100-Bold" {...props} />;

export default H100BoldDiv;
