import styled from "styled-components";
import { colorMixin } from "cssmixins";

const DivStyled = styled(({ margin, height, ...props }) => <div {...props} />)`
  ${colorMixin}
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
  ${({ height }) => (height !== undefined ? `height:${height}px;` : "")}
`;

const P14Div = (props) => <DivStyled className="P14" {...props} />;

export default P14Div;
