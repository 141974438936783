import styled from "styled-components";

const Column = ({ children, margin, gap }) => {
  return (
    <Container margin={margin} gap={gap}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ gap }) => (gap !== undefined ? `gap:${gap}px;` : "")}
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
`;

export default Column;
