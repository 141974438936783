import styled from "styled-components";
import { colorMixin } from "cssmixins";
import { forwardRef } from "react";

const StyledDiv = styled.div`
  ${colorMixin}
`;

const H400BlackDiv = forwardRef(({ className, ...props }, ref) => (
  <StyledDiv className={`H400-Black ${className}`} {...props} ref={ref} />
));

export default H400BlackDiv;
