import { useEffect } from "react";
import { useSlice } from "slices";

export const useSetActiveSectionIndex = (activeSectionIndex) => {
  const [, setActiveSectionIndex] = useSlice("activeSectionIndex");

  useEffect(() => {
    setActiveSectionIndex(activeSectionIndex);
  }, [setActiveSectionIndex, activeSectionIndex]);
};
