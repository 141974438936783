import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import i18n from "i18n";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { Provider } from "slices";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import reportWebVitals from "./reportWebVitals";

import RootLayout from "routes/root/layout";
import RootIndex from "routes/root/index";

import LMSUserGuideLayout from "routes/lms-user-guide/layout";
import LMSUserGuideIndex from "routes/lms-user-guide/index";
import LMSUserGuideTeacherLayout from "routes/lms-user-guide/teacher/layout";
import LMSUserGuideAdminschoolLayout from "routes/lms-user-guide/adminschool/layout";
import LMSUserGuideAdminschoolIndex from "routes/lms-user-guide/adminschool/index";
import LMSUserGuideSuperadminLayout from "routes/lms-user-guide/superadmin/layout";
import LMSUserGuideSuperadminIndex from "routes/lms-user-guide/superadmin/index";

import LMSUserGuideTeacherConceptosBasicos from "routes/lms-user-guide/teacher/conceptos-basicos";
import LMSUserGuideTeacherPlanifica from "routes/lms-user-guide/teacher/planifica";
import LMSUserGuideTeacherSesiones from "routes/lms-user-guide/teacher/sesiones";
import LMSUserGuideTeacherReplanifica from "routes/lms-user-guide/teacher/replanifica";
import LMSUserGuideTeacherPracticas from "routes/lms-user-guide/teacher/practicas";
import LMSUserGuideTeacherInformes from "routes/lms-user-guide/teacher/informes";
import LMSUserGuideTeacherEstudiantes from "routes/lms-user-guide/teacher/estudiantes";
import LMSUserGuideTeacherRecursos from "routes/lms-user-guide/teacher/recursos";

import LMSUserGuideAdminschoolRoles from "routes/lms-user-guide/adminschool/roles";
import LMSUserGuideAdminschoolTimetable from "routes/lms-user-guide/adminschool/timetable";
import LMSUserGuideAdminschoolClassrooms from "routes/lms-user-guide/adminschool/classrooms";
import LMSUserGuideAdminschoolUsers from "routes/lms-user-guide/adminschool/users";
import LMSUserGuideAdminschoolResources from "routes/lms-user-guide/adminschool/resources";

import TrackingToolGuideIndex from "routes/tracking-tool-guide/index";
import TrackingToolGuideLayout from "routes/tracking-tool-guide/layout";
import TrackingToolGuideTeacherLayout from "routes/tracking-tool-guide/teacher/layout";
import TrackingToolGuideTeacherFirstQuestions from "routes/tracking-tool-guide/teacher/first-questions";
import TrackingToolGuideTeacherFirstSteps from "routes/tracking-tool-guide/teacher/first-steps";
import TrackingToolGuideTeacherGeneral from "routes/tracking-tool-guide/teacher/general";

const lmsUserGuideTeacherPages = [
  { id: "conceptos-basicos", component: LMSUserGuideTeacherConceptosBasicos },
  { id: "planifica", component: LMSUserGuideTeacherPlanifica },
  { id: "sesiones", component: LMSUserGuideTeacherSesiones },
  { id: "replanifica", component: LMSUserGuideTeacherReplanifica },
  { id: "practicas", component: LMSUserGuideTeacherPracticas },
  { id: "informes", component: LMSUserGuideTeacherInformes },
  { id: "estudiantes", component: LMSUserGuideTeacherEstudiantes },
  { id: "recursos", component: LMSUserGuideTeacherRecursos },
];

const lmsUserGuideAdminschoolPages = [
  { id: "roles", component: LMSUserGuideAdminschoolRoles },
  { id: "timetable", component: LMSUserGuideAdminschoolTimetable },
  { id: "classrooms", component: LMSUserGuideAdminschoolClassrooms },
  { id: "users", component: LMSUserGuideAdminschoolUsers },
  { id: "resources", component: LMSUserGuideAdminschoolResources },
];

const trackingToolGuideTeacherPages = [
  { id: "first-questions", component: TrackingToolGuideTeacherFirstQuestions },
  { id: "first-steps", component: TrackingToolGuideTeacherFirstSteps },
  { id: "general", component: TrackingToolGuideTeacherGeneral },
];

{/*
  Main URLs:
    LMS
      es/lms-user-guide/teacher
      es/lms-user-guide/adminschool
      es/lms-user-guide/superadmin
      es/tracking-tool-guide
*/}

const baseUrl = "/" + i18n.language;
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={baseUrl} element={<RootLayout />}>
      <Route index element={<RootIndex />} />

      <Route path="lms-user-guide" element={<LMSUserGuideLayout />}>
        <Route index element={<LMSUserGuideIndex />} />

        <Route path="teacher" element={<LMSUserGuideTeacherLayout />}>
          <Route index element={<LMSUserGuideTeacherConceptosBasicos />} />
          {lmsUserGuideTeacherPages.map((page) => (
            <Route key={page.id} path={page.id} element={<page.component />} />
          ))}
        </Route>

        <Route path="adminschool" element={<LMSUserGuideAdminschoolLayout />}>
          <Route index element={<LMSUserGuideAdminschoolRoles />} />
          {lmsUserGuideAdminschoolPages.map((page) => (
            <Route key={page.id} path={page.id} element={<page.component />} />
          ))}
        </Route>

        <Route path="superadmin" element={<LMSUserGuideSuperadminLayout />}>
          <Route index element={<LMSUserGuideSuperadminIndex />} />
        </Route>

      </Route>

      <Route path="tracking-tool-guide" element={<TrackingToolGuideLayout />}>
        <Route index element={<TrackingToolGuideIndex />} />
        <Route path="teacher" element={<TrackingToolGuideTeacherLayout />}>
          <Route index element={<TrackingToolGuideTeacherFirstQuestions />} />
          {trackingToolGuideTeacherPages.map((page) => (
            <Route key={page.id} path={page.id} element={<page.component />} />
          ))}
        </Route>
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
