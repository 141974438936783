import styled from "styled-components";

const IconSVG = styled(({ width, height, color, ...props }) => (
  <img {...props} />
))`
  ${({ width }) => (width !== undefined ? `min-width:${width}px;` : "")}
  ${({ height }) => (height !== undefined ? `min-height:${height}px;` : "")}
  ${({ theme, color }) => (!!color ? `color:${theme.colors[color]};` : "")}
`;

export default IconSVG;
