import styled from "styled-components";

//prettier-ignore
const StyledDiv = styled(({margin, color,type,foregroundColor, ...props})=><div {...props} />)`
  border-radius:${({type,theme})=>theme[type??'button1'].border.radius}px;
  background-color:${({theme,color})=>!!color?theme.colors[color]:theme.colors.white};
  ${({type,theme:{[type??'button1']:{padding:{top,bottom,left,right}}}})=>`padding:${top}px ${right}px ${bottom}px ${left}px;`}
  display:inline-block;
  ${({margin})=>!!margin?`margin:${margin};`:''}
  ${({ theme, foregroundColor }) => !!foregroundColor?`color:${theme.colors[foregroundColor]};`:''}
`;

const Button = (props) => <StyledDiv {...props} />;

export default Button;
