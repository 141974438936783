import styled from "styled-components";
import { colorMixin } from "cssmixins";

const StyledSpan = styled.span`
  ${colorMixin}
`;

const P14BoldSpan = (props) => <StyledSpan className="P14-Bold" {...props} />;

export default P14BoldSpan;
