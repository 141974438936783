import styled from "styled-components";

const StyledSpan = styled(({ width, height, color, ...props }) => (
  <span {...props} />
))`
  ${({ width }) => (width !== undefined ? `min-width:${width}px;` : "")}
  ${({ height }) => (height !== undefined ? `min-height:${height}px;` : "")}
  ${({ theme, color }) => (!!color ? `color:${theme.colors[color]};` : "")}
`;

const Icon = ({ name, className, ...props }) => (
  <span className={className}>
    <StyledSpan className="iconify" data-icon={name} {...props} />
  </span>
);

export default Icon;
