import { useState, useEffect, useCallback, useMemo } from "react";
import throttle from "lodash/fp/throttle";

export const useScrollspy = ({
  activeSectionDefault = 0,
  offsetPx = 0,
  scrollingElementRef,
  sectionElementsRef,
  throttleMs = 100,
}) => {
  const [activeSection, setActiveSection] = useState(activeSectionDefault);

  const handle = useCallback(() => {
    const scrollingElement = scrollingElementRef.current;
    const sectionElements = sectionElementsRef.current;
    const scrollingElementRect = scrollingElement?.getBoundingClientRect();
    const scrollingElementHeight =
      scrollingElementRect?.bottom - scrollingElementRect?.top;
    let currentSectionId = activeSection;

    for (let i = 0; i < sectionElements.length; i += 1) {
      const section = sectionElements[i];
      if (!section || !(section instanceof Element)) continue;
      const rect = section.getBoundingClientRect();
      if (rect.top + offsetPx >= 0) {
        if (rect.top > scrollingElementHeight) {
          currentSectionId = i - 1;
          break;
        }
        currentSectionId = i;
        if (i !== 0) {
          break;
        }
      }
    }

    if (scrollingElement?.scrollTop === 0) {
      currentSectionId = 0;
    }

    setActiveSection(currentSectionId);
  }, [activeSection, sectionElementsRef, scrollingElementRef, offsetPx]);

  const throttledHandle = useMemo(
    () => throttle(throttleMs, handle),
    [handle, throttleMs]
  );

  useEffect(() => {
    const scrollable = scrollingElementRef.current;
    if (scrollable) {
      scrollable.addEventListener("scroll", throttledHandle);
    }
    return () => {
      if (scrollable) {
        scrollable.removeEventListener("scroll", throttledHandle);
      }
    };
  }, [scrollingElementRef, throttledHandle]);

  return activeSection;
};
