const theme = {
  background: {
    color: "#f8fafc",
  },
  menu: {
    width: 208,
    border: {
      width: 1,
      radius: 8,
      color: "#ffffff",
    },
    background: {
      color: "#ffffff",
    },
    margin: {
      right: 55,
    },
  },
  menuItem: {
    titleContainer: {
      height: 32,
      padding: {
        left: 24,
        right: 13,
        top: 28,
        bottom: 28,
      },
    },
    subitemContainer: {
      padding: {
        left: 24,
        right: 13,
        top: 17,
        bottom: 17,
      },
    },
  },
  button1: {
    border: {
      radius: 4,
    },
    padding: {
      left: 18,
      right: 18,
      top: 7,
      bottom: 6,
    },
  },
  button2: {
    border: {
      radius: 4,
    },
    padding: {
      left: 32,
      right: 32,
      top: 12,
      bottom: 12,
    },
  },
  button3: {
    border: {
      radius: 4,
    },
    padding: {
      left: 16,
      right: 16,
      top: 10,
      bottom: 10,
    },
  },
  label: {
    border: {
      radius: 4,
    },
    padding: {
      left: 10,
      right: 10,
      top: 8,
      bottom: 8,
    },
  },
  box: {
    border: {
      radius: 4,
      width: 1,
    },
    shadow: {
      offsetX: 0,
      offsetY: 0,
      blurRadius: 8,
      spreadRadius: 2,
      color: {
        red: 0,
        green: 29,
        blue: 51,
        alpha: 0.15,
      },
    },
    padding: {
      vertical: 16,
      horizontal: 12,
    },
  },
  colors: {
    neutral90: "#374047",
    neutral80: "#626e77",
    neutral70: "#818a92",
    primary50: "#0d70b6",
    primary30: "#88b9db",
    primary10: "#e4eff7",
    warning80: "#664900",
    warning50: "#ffcd4d",
    warning10: "#fff1cc",
    info80: "#002266",
    info50: "#4d89ff",
    info10: "#ccddff",
    success50: "#50ca5c",
    critical50: "#ff4d4d",
    lime100: "#384404",
    lime40: "#c7e34d",
    pink90: "#7f004a",
    pink60: "#da85b7",
    pink50: "#e29fc6",
    pink10: "#f8e9f2",
    green90: "#064A00",
    green50: "#5ACA50",
    green10: "#DCF4DA",
    white: "#ffffff",
    green: "green",
    brown: "brown",
    yellow: "yellow",
    suspenso: "#BC768E",
    bien: "#EAA700",
    notable: "#C2C200",
    sobresaliente: "#458529",
    jmcBlue: "#154B7C",
    jmcBlue5: "#63779B",
    jmcBlue5Bis: "#E9EDF1",
    jmcBlue3x: "#8498B5",
    jmcBlue15: "#C8D2DD",
    jmcGrey: "#454448",
    jmcBackground: "#F4F4F6",
    black: "#4C4C4E",
  },
  image: {
    width: "60%",
    maxWidth: "100%",
    box: {
      maxWidth: "79%",
    },
    button: {
      maxWidth: "100%",
      container: {
        maxWidth: "165px",
      },
    },
  },
  video: {
    maxWidth: "87%",
  },
  dimensions: {
    title: {
      marginBottom: 20,
    },
    paragraph: {
      margin: { bottom: 20 },
    },
    boxLegendGap: 10,
    icon: {
      tiny: 16,
      small: 20,
      medium: 24,
      big: 48,
    },
    pageContainer: {
      padding: {
        right: 150,
      },
    },
    boxIconDescription: { width: 100, height: 46 },
    iFrame: {
      marginHorizontal: 30,
    },
    box: {
      margin: 30,
    },
  },
};

export default theme;
