import styled from "styled-components";

const IFrame = ({ title, src, margin }) => {
  return (
    <Container margin={margin}>
      <StyledIFrame
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture;"
        allowFullScreen
        title={title}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 58.07% 0 0 0;
  position: relative;
  ${({ margin }) => (!!margin ? `margin:${margin};` : "")}
`;

const StyledIFrame = styled.iframe`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

export default IFrame;
